export const partnerNameMap = {
  HBANK: "HDFC BANK",
  BFL: "BAJAJ FINSERV",
  HLEMP: "EMPLOYEE",
  CML: "CHOLA MANDALAM",
  HSL: "HDFC SECURITIES LTD.",
  EDM: "HDFC LIFE",
  HLIFE: "HDFC LIFE",
  HLIFEOM: "HDFC LIFE",
  AGBR: "ANGEL BROKING",
  RBL: "RBL",
  SBANK: "SARASWAT BANK LTD.",
  BROCA: "BROCA HDFC LIFE",
  BROCAOM: "BROCA HDFC LIFE",
  BROCAOMA: "BROCA HDFC LIFE",
  BROCABELAIR: "BROCA HDFC LIFE",
  SURYODAY: "SURYODAY BANK",
  STOCKHOLDING: "STOCK HOLDING",
  COSMOS: "COSMOS BANK",
  SANGLIURBAN: "SANGLI URBAN BANK",
  BHARATBANK: "BHARAT BANK",
  AGENCY: "AGENCY",
  AGENCYOM: "AGENCY",
  DIRECT: "DIRECT",
  DIRECTOM: "DIRECT",
  IDFC: "IDFC",
  PAYTM: "PAYTM",
  TATACAPITAL: "TATA CAPITAL",
  FISDOM: "FISDOM",
  HBANKUPSELL: "HDFC BANK UPSELL",
  DNS: "DNS",
  JJSB: "JJSB",
  BCCB: "BCCB",
  CCCB: "CCCB",
  HDFCSALES: "HDFC SALES",
  PASAPITCH: "PASA PITCH CALULATOR",
  DCBBANK: "DCB BANK",
  CREDITMANTRI: "CREDIT MANTRI",
  HSLOM: "HDFC SECURITIES",
  HSALES: "HDFC SALES",
  HDFCSALESOM: "HDFC SALES",
  CLIX: "CLIX CAPITAL",
  POLICYBAZAR: "POLICY BAZAR",
  COVERFOX: "COVERFOX",
  SARASWATBANK: "SARASWAT BANK LTD."
};

const productFilterOut = (items) => {
  const disabledProducts = process.env?.REACT_APP_PRODUCTS_DISABLED.split(",");
  return items?.filter((product) => !disabledProducts.includes(product));
};

export const ActivePartnerList = [
  {
    source: "HBANK",
    products: productFilterOut([
      "SJB",
      "C2PS",
      "C2PL",
      "SANCHAYPAR",
      "SANCHAYPLUS",
      'C2A',
      'GWP',
      'ESJ'
    ]),

    channels: []
  },
  {
    source: "HBANK",
    products: productFilterOut(["SJB", "C2PL", "SANCHAYPAR", "SANCHAYPLUS", "C2A", "GWP",'ESJ']),
    channels: []
  },
  {
    source: "BELAIR",
    products: productFilterOut(["SANCHAY", "SANCHAYPAR", "SANCHAYPLUS"]),
    channels: []
  },
  {
    source: "HSL",
    products: productFilterOut(["SJB", "C2PL", "SANCHAYPAR", "SANCHAYPLUS"]),
    channels: []
  },
  {
    source: "HLEMP",
    products: productFilterOut([]),
    channels: []
  },
  {
    source: "CML",
    products: productFilterOut(["SJB", "C2PL", "SANCHAYPAR", "SANCHAYPLUS"]),
    channels: []
  },
  {
    source: "EDM",
    products: productFilterOut(["SJB", "C2PL", "SANCHAYPAR", "SANCHAYPLUS"]),
    channels: [
      "WP",
      "SBP",
      "SOP",
      "SMP",
      "MAP",
      "IMP",
      "ES",
      "TCVM",
      "TURTLEMINT",
      "ELEPHANTINSURANCE",
      "MYINSURANCECLUB",
      "WAY2WEALTH",
      "PAYBIMA",
      "INSTABIMA",
      "INSTAINSURE",
      "MONEYFROG",
      "RUPEEVEST",
      "PRABHUDASLILADHAR",
      "FINNOVATE",
      "HEARTBEATINSURANCE",
      "ETMONEY",
      "FINCART",
      "WISHFIN",
      "POLICYX",
      "BIMAKARO",
      "EASYPOLICY",
      "INSTANTCOVER",
      "ASTROINSURANCE",
      "POLICYBACHAT",
      "COVERFOX",
      "COVERDRIVE",
      "ZOPPER",
      "MARSH",
      "INTEGRATEDRISK"
    ]
  },
  {
    source: "HLIFE",
    products: productFilterOut(["SJB", "C2PL", "SANCHAYPAR", "SANCHAYPLUS"]),
    channels: []
  },
  {
    source: "BFL",
    products: productFilterOut(["SJB", "C2PL", "SANCHAYPAR", "SANCHAYPLUS"]),
    channels: []
  },
  {
    source: "BROCA",
    products: productFilterOut(["SJB", "C2PL", "SANCHAYPAR", "SANCHAYPLUS"]),
    channels: [
      "VIZZA",
      "PROBUS",
      "IEP",
      "BAJAJ",
      "ANAND",
      "SMC",
      "BONANZA",
      "ANGEL",
      "IIFL",
      "GENNEXT",
      "NJ",
      "BLUECHIP",
      "DCB"
    ]
  },
  {
    source: "RBL",
    products: productFilterOut(["SJB", "C2PL", "SANCHAYPAR", "SANCHAYPLUS"]),
    channels: []
  },
  {
    source: "SBANK",
    products: productFilterOut(["SJB", "C2PL", "SANCHAYPAR", "SANCHAYPLUS"]),
    channels: []
  },
  {
    source: "DIRECT",
    products: productFilterOut(["SJB", "C2PL", "SANCHAYPAR", "SANCHAYPLUS"]),
    channels: []
  },
  {
    source: "CLIX",
    products: productFilterOut(["SJB", "C2PL", "SANCHAYPAR", "SANCHAYPLUS"]),
    channels: []
  },
  {
    source: "AGENCY",
    products: productFilterOut(["SJB", "C2PL", "SANCHAYPAR", "SANCHAYPLUS"]),
    channels: []
  },
  {
    source: "HBANKNR",
    products: productFilterOut(["SJB", "C2PL", "SANCHAYPAR", "SANCHAYPLUS", "C2A", "GWP", 'ESJ']),
    channels: []
  },
  {
    source: "SURYODAY",
    products: productFilterOut(["SJB", "C2PL", "SANCHAYPAR", "SANCHAYPLUS"]),
    channels: []
  },
  {
    source: "STOCKHOLDING",
    products: productFilterOut(["SJB", "C2PL", "SANCHAYPAR", "SANCHAYPLUS"]),
    channels: []
  },
  {
    source: "COSMOS",
    products: productFilterOut(["SJB", "C2PL", "SANCHAYPAR", "SANCHAYPLUS"]),
    channels: []
  },
  {
    source: "SANGLIURBAN",
    products: productFilterOut(["SJB", "C2PL", "SANCHAYPAR", "SANCHAYPLUS"]),
    channels: []
  },
  {
    source: "BHARATBANK",
    products: productFilterOut(["SJB", "C2PL", "SANCHAYPAR", "SANCHAYPLUS"]),
    channels: []
  },
  {
    source: "BROCAOM",
    products: productFilterOut(["SJB", "C2PL", "SANCHAYPAR", "SANCHAYPLUS"]),
    channels: [
      "NJ",
      "BLUECHIP",
      "MOTILAL",
      "CITADEL",
      "GENNEXT",
      "SBICAP",
      "BAJAJ",
      "IEP",
      "RELIANCE",
      "IIFL",
      "ANGEL",
      "BONANZA",
      "PROBUS",
      "VIZZA",
      "AURIC",
      "RRIB",
      "LANDMARK",
      "SMC",
      "SPECTRUM",
      "ELITE",
      "SANSHI",
      "SHIVOM",
      "SSOODCORP",
      "MASTERINSURANCE",
      "MADHAVINSURANCE",
      "SRASSOCIATEREALMART",
      "ANANDRATHI",
      "CAPITAL",
      "NOVELTY",
      "VULCAN",
      "EFFICIENT",
      "ENIVESH",
      "VERACITY",
      "IMPETUS",
      "YELLA",
      "GEOJIT",
      "ICICISEC"
    ]
  },
  {
    source: "IDFC",
    products: productFilterOut(["SJB", "C2PL", "SANCHAYPAR", "SANCHAYPLUS"]),
    channels: []
  },
  {
    source: "HBANKUPSELL",
    products: productFilterOut(["SJB", "C2PL", "SANCHAYPAR", "SANCHAYPLUS"]),
    channels: []
  },
  {
    source: "PAYTM",
    products: productFilterOut(["SJB", "C2PL", "SANCHAYPAR", "SANCHAYPLUS"]),
    channels: []
  },
  {
    source: "FISDOM",
    products: productFilterOut(["SJB", "C2PL", "SANCHAYPAR", "SANCHAYPLUS"]),
    channels: []
  },
  {
    source: "TATACAPITAL",
    products: productFilterOut(["SJB", "C2PL", "SANCHAYPAR", "SANCHAYPLUS"]),
    channels: []
  },
  {
    source: "DNS",
    products: productFilterOut(["SJB", "C2PL", "SANCHAYPAR", "SANCHAYPLUS"]),
    channels: []
  },
  {
    source: "BCCB",
    products: productFilterOut(["SJB", "C2PL", "SANCHAYPAR", "SANCHAYPLUS"]),
    channels: []
  },
  {
    source: "CCCB",
    products: productFilterOut(["SJB", "C2PL", "SANCHAYPAR", "SANCHAYPLUS"]),
    channels: []
  },
  {
    source: "JJSB",
    products: productFilterOut(["SJB", "C2PL", "SANCHAYPAR", "SANCHAYPLUS"]),
    channels: []
  },
  {
    source: "HDFCSALES",
    products: productFilterOut(["SJB", "C2PL", "SANCHAYPAR", "SANCHAYPLUS"]),
    channels: []
  },
  {
    source: "PASAPITCH",
    products: productFilterOut(["SJB", "C2PL", "SANCHAYPAR", "SANCHAYPLUS"]),
    channels: []
  },
  {
    source: "POLICYBAZAR",
    products: productFilterOut(["SJB", "C2PL", "SANCHAYPAR", "SANCHAYPLUS"]),
    channels: []
  },
  {
    source: "HSLOM",
    products: productFilterOut(["SJB", "C2PL", "SANCHAYPAR", "SANCHAYPLUS"]),
    channels: ["TVS", "DS"]
  },
  {
    source: "DCBBANK",
    products: productFilterOut(["SJB", "C2PL", "SANCHAYPAR", "SANCHAYPLUS"]),
    channels: []
  },
  {
    source: "CREDITMANTRI",
    products: productFilterOut(["SJB", "C2PL", "SANCHAYPAR", "SANCHAYPLUS"]),
    channels: []
  },
  {
    source: "HDFCSALESOM",
    products: productFilterOut(["SJB", "C2PL", "SANCHAYPAR", "SANCHAYPLUS"]),
    channels: []
  },
  {
    source: "COVERFOX",
    products: productFilterOut(["SJB", "C2PL", "SANCHAYPAR", "SANCHAYPLUS"]),
    channels: []
  },
  {
    source: "AGENCYOM",
    products: productFilterOut(["SJB", "C2PL", "SANCHAYPAR", "SANCHAYPLUS"]),
    channels: []
  },
  {
    source: "DIRECTOM",
    products: productFilterOut(["SJB", "C2PL", "SANCHAYPAR", "SANCHAYPLUS"]),
    channels: []
  },
  {
    source: "RBLOM",
    products: productFilterOut(["SJB", "C2PL", "SANCHAYPAR", "SANCHAYPLUS"]),
    channels: []
  },
  {
    source: "HLIFEOM",
    products: productFilterOut(["SJB", "C2PL", "SANCHAYPAR", "SANCHAYPLUS"]),
    channels: []
  },
  {
    source: "RBLCC",
    products: productFilterOut(["SJB", "C2PL", "SANCHAYPAR", "SANCHAYPLUS"]),
    channels: []
  },
  {
    source: "SARASWATBANK",
    products: productFilterOut(["SJB", "C2PL", "SANCHAYPAR", "SANCHAYPLUS"]),
    channels: []
  },
  {
    source: "WHATSAPP-MYACCOUNT",
    products: productFilterOut([]),
    channels: []
  },
  {
    source: "HBANKOM",
    products: productFilterOut([
      "SJB",
      "C2PL",
      "SANCHAYPAR",
      "SANCHAYPLUS",
      "C2PS",
      "C2A",
      "GWP",
      'ESJ'
    ]),
    channels: []
  },
  {
    source: "BROCAOMA",
    products: productFilterOut(["SJB", "C2PL", "SANCHAYPAR", "SANCHAYPLUS"]),
    channels: ["AURIC", "RRIB", "LANDMARK", "SMC", "SPECTRUM", "ELITE"]
  },
  {
    source: "BROCABELAIR",
    products: productFilterOut(["SANCHAYPAR", "SANCHAYPLUS"]),
    channels: []
  },
  {
    source: "BROCABELAIRNO",
    products: productFilterOut(["SANCHAYPAR", "SANCHAYPLUS"]),
    channels: ["VIZZA", "PROBUS", "IIFL", "SMC", "BAJAJ", "BLUECHIP"]
  },
  {
    source: "IDFCOM",
    products: productFilterOut(["SANCHAYPAR", "SANCHAYPLUS"]),
    channels: []
  }
];

export const BELAIR_PARTNER = ["BROCABELAIR", "BROCABELAIRNO"];
