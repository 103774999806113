import Bowser from "bowser";
import { NOMINEE_RELATIONSHIP, productMap } from "../configs";
import { freqOptions } from "../configs";
import Moment from "moment";
import { PASA_API } from "../configs/api";
import axios from "axios";
import { Encrypt } from "../modules";
import crypto from "crypto";
export const makeOfferObj = (baseObj, product, partner) => {
  var {
    frequency,
    smoker,
    occupation,
    graduate,
    term,
    ppt,
    height,
    weight,
    pptOption,
    pasa,
    plan,
    previousSA,
    education,
    cashBonusTiming,
    ppsFlg,
    ppslifeFlg,
    ppsMopFlg,
    rop,
    subOption,
    deathBenefitMultiple,
    inputType = "premium_input",
    benefit,
    pyfrequency,
    MaturityBenefit,
    SurPercent,
    MoneyBkStruct,
    Regfreq,
    cashbackPayout,
    cashback,
    SurPayOutPd,
    defermentPeriod,
    payout_type,
    pyIncPercent,
    ropPercent,
    tmROP,
    premium,
    pyPeriod,
    payoutFrequency,
    dbMultiple,
    benefitsCombination,
    bonusOption,
    paidUpAddition,
    survivalBenefitOption,
    cashBonusStartYear,
    limitedTerm
    
    
  } = baseObj;
  smoker = smoker ? smoker : false;
  graduate = graduate ? graduate : false;
  let payload = {
    frequency,
    smoker,
    occupation,
    graduate,
    term,
    ppt
  };
  if (weight !== undefined) {
    payload["weight"] = weight;
  }
  if (
    height &&
    height.feet !== undefined &&
    height.feet !== "" &&
    height.inches !== undefined &&
    height.inches !== ""
  ) {
    payload["height"] = height;
  } else {
    delete payload["height"];
  }
  if (Number(previousSA) > 0) {
    payload["previousSA"] = previousSA;
  } else {
    delete payload["previousSA"];
  }

  switch (product) {
    case "ESJ":
      payload["premium"] = premium;
      payload["plan"] = plan;
      payload["benefitsCombination"] = benefitsCombination;
      payload["payoutFrequency"] = payoutFrequency;
      payload["dbMultiple"] = dbMultiple;
      payload["survivalBenefitOption"] = survivalBenefitOption;
      payload["bonusOption"] = bonusOption;
      payload["paidUpAddition"] = paidUpAddition;
      if (bonusOption === "3" && survivalBenefitOption === "1") {
        payload["cashBonusStartYear"] = cashBonusStartYear;
        payload["limitedTerm"] = limitedTerm;
      }
      break;
    case "GWP":
      payload["premium"] = premium;
      payload["plan"] = plan;

      if (plan === "Lump Sum Variant") {
        payload["pptOption"] = pptOption
      }


      if (plan === "Income Variant") {
        payload["pyPeriod"] = pyPeriod;
        payload["pyfrequency"] = pyfrequency;
        payload["term"] = term;
        payload["deathBenefitMultiple"] = deathBenefitMultiple;
      }
      break;
    case "C2A":
      payload["deathBenefitMultiple"] = deathBenefitMultiple || '7';
      payload["education"] = education;
      if (subOption === "Lumpsum") {
        if (inputType === "premium_input") {
          payload["premium"] = premium;
        }

        if (inputType === "benefit_input") {
          payload["benefit"] = benefit || MaturityBenefit;
        }

        payload["subOption"] = "Lumpsum";
      }
      if (subOption === "Moneyback") {
        payload["pyfrequency"] =
          typeof pyfrequency === "string" ? pyfrequency : "annual";
        payload["SurPercent"] = SurPercent;
        payload["MoneyBkStruct"] = MoneyBkStruct;

        if (inputType === "premium_input") {
          payload["premium"] = premium;
        }

        if (inputType === "benefit_input") {
          payload["benefit"] = benefit;
        }

        payload["subOption"] = "Moneyback";
        if (MoneyBkStruct?.toLowerCase() === "regular") {
          payload["Regfreq"] = Regfreq;
        }
      }
      if (subOption === "Early Income") {
        payload["cashback"] = cashback;
        if (cashback === "yes") {
          payload["cashbackPayout"] = cashbackPayout
        }
        if (inputType === "premium_input") {
          payload["premium"] = premium;
        }
        if (inputType === "benefit_input") {
          payload["benefit"] = benefit;
        }
        payload["pyfrequency"] = pyfrequency;
        payload["subOption"] = "Early Income";
        payload["SurPayOutPd"] = String(SurPayOutPd);
        payload["defermentPeriod"] = String(defermentPeriod);
        payload["payout_type"] = payout_type;
        payload["term"] = +defermentPeriod + +SurPayOutPd;
        if (payout_type === "Increasing") {
          payload["pyIncPercent"] = pyIncPercent;
        }
        payload["rop"] = rop;
        if (rop === "Yes") {
          payload["ropPercent"] = ropPercent;
        }
      }
      if (subOption === "Income") {
        payload["pyfrequency"] = pyfrequency;
        if (inputType === "premium_input") {
          payload["premium"] = premium;
        }

        if (inputType === "benefit_input") {
          payload["benefit"] = benefit;
        }

        if (lifeCover?.toLowerCase() === "yes") {
          payload["term"] = +ppt + +defermentPeriod + +SurPayOutPd;
        } else {
          payload["term"] = +ppt + +defermentPeriod;
        }

        payload["subOption"] = "Income";
        payload["defermentPeriod"] = String(defermentPeriod);
        payload["lifeCover"] = lifeCover;
        payload["SurPayOutPd"] = String(SurPayOutPd);
        payload["rop"] = rop;
        payload["payout_type"] = payout_type;
        if (payout_type === "Increasing") {
          payload["pyIncPercent"] = pyIncPercent;
        }
        if (rop === "Yes") {
          payload["ropPercent"] = ropPercent;
          payload["tmROP"] = tmROP;
        }
      }
      break;
    case "C2PL":
      if (pptOption === "SINGLE") {
        payload["frequency"] = "single";
      } else {
        payload["frequency"] = frequency;
      }
      payload["pptOption"] = pptOption || "REGULAR";
      payload["pasa"] = pasa;
      payload["plan"] = plan || "noWholeLife";
      payload["education"] = education;
      break;
    case "SJB":
      payload["pptOption"] = pptOption || "REGULAR";
      payload["pasa"] = pasa;
      payload["education"] = education;
      break;
    case "C2PS":
      if (pptOption === "SINGLE") {
        payload["frequency"] = "single";
      } else {
        payload["frequency"] = frequency;
      }
      payload["education"] = education;
      payload["pptOption"] = pptOption || "REGULAR";
      payload["pasa"] = pasa;
      payload["plan"] = baseObj["plan"] || baseObj?.POD?.option || "Life";

      if (payload.plan === "Life") {
        payload["saFactor"] = baseObj["saFactor"] || baseObj?.POD?.saf || "A";

        payload["ppsFlg"] = ppsFlg
          ? String(ppsFlg) == "false"
            ? false
            : true
          : false;
        if (String(ppsFlg) === "true") {
          payload["ppslifeFlg"] = ppslifeFlg || "Single";
          payload["ppsMopFlg"] = ppsMopFlg || "Annual";
        }
      }

      if (payload.plan === "Life" || payload.plan === "Life Plus") {
        // payload["rop"] = baseObj["rop"] || baseObj?.POD?.rop || "No";
        payload["rop"] = rop;
      }
      if (payload.plan === "Life Goal") {
        payload["levelCoverPeriod"] =
          baseObj["levelCoverPeriod"] || baseObj?.POD?.pla || ppt;
      }
      break;
    case "SANCHAYPLUS":
      payload["plan"] = baseObj["plan"] || "Guaranteed Maturity";
      payload["premium"] = baseObj["premium"];
      payload["education"] = education;
      if (payload.plan !== "Guaranteed Maturity") {
        payload["pyfrequency"] = baseObj["pyfrequency"] || "annual";
      } else {
        delete payload.pyfrequency;
        if (partner === "hsl") {
          payload.plan = "Guaranteed Income";
          payload.term = 11;
        }
      }
      if (baseObj?.incomeTerm) {
        payload["incomeTerm"] = baseObj?.incomeTerm || undefined;
      }
      if (baseObj?.defermentPeriod) {
        payload["defermentPeriod"] = baseObj?.defermentPeriod || undefined;
      }
      // delete payload.ppt;
      delete payload.pptOption;
      break;
    case "SANCHAY":
      delete baseObj.plan;
      payload["pasa"] = baseObj["pasa"];
      break;
    case "SANCHAYPAR":
      delete payload["pasa"];
      payload["education"] = education;
      payload["premium"] = baseObj["premium"];
      payload["plan"] = baseObj["plan"] || "Immediate Income";
      payload["pyfrequency"] = baseObj["pyfrequency"];
      payload["survPyOpt"] = baseObj["survPyOpt"] || "Survival Benefit";
      if (cashBonusTiming === "Arrear") {
        payload["survPyDt"] = baseObj["survPyDt"] || Moment().format("DD/MM");
      } else {
        payload["survPyDt"] = Moment().format("DD/MM");
      }
      payload["cashBonusTiming"] = cashBonusTiming || "Arrear";
      break;
    default:
      break;
  }
  if (partner === "AGENCY" || partner === "AGENCYOM") {
    payload["bypassNmlNfl"] = baseObj["bypassNmlNfl"];
  }
  return payload;
};

export const dataMapper = (baseObj, target) => {
  Object.keys(target).map((key) => {
    baseObj[key] = target[key];
  });
  return baseObj;
};

export function formatMoney(amt) {
  let formattedAmt = !!amt ? parseInt(amt, 0).toLocaleString("en-IN") : 0;
  return `₹ ${formattedAmt}`;
}

export function getLabelByFreq(freq) {
  switch ((freq || "").toLowerCase()) {
    case "annual":
      return " / Yearly";
    case "halfyearly":
      return " / Half Yearly";
    case "quarterly":
      return " / Quarterly";
    case "monthly":
      return " / Monthly";
    default:
      return "";
  }
}

export function showPayoutByPyFreq(amount, freq) {
  switch (freq) {
    case "annual":
      return `${formatMoney(Number(amount))}`;
    case "halfyearly":
      return `${formatMoney(Number(amount) / 2)}`;
    case "quarterly":
      return `${formatMoney(Number(amount) / 4)}`;
    case "monthly":
      return `${formatMoney(Number(amount) / 12)}`;
    default:
      return `${formatMoney(Number(amount))}`;
  }
}

export function shrinkNoWObj(rootMap, dropMap) {
  let merged = {};
  try {
    Object.keys(rootMap).forEach((key) => {
      merged[key] = [];
      rootMap[key].map((i) => {
        let diff = [];
        if (dropMap[key]) {
          diff = rootMap[key].filter((x) => !dropMap[key].includes(x));
          merged[key].push({
            value: i,
            valid: !diff.includes(i) ? false : true
          });
        } else {
          merged[key].push({ value: i, valid: true });
        }
      });
    });
  } catch (err) {
    merged = {};
  }
  return merged;
}

export class BMICalculator {
  constructor(feet, inches, wt) {
    this.ftHeight = feet;
    this.inHeight = inches;
    this.weight = wt;
  }
  calculate = () => {
    this.ftHeight =
      typeof this.ftHeight === "string"
        ? parseInt(this.ftHeight)
        : this.ftHeight;
    this.inHeight =
      typeof this.inHeight === "string"
        ? parseInt(this.inHeight)
        : this.inHeight;
    this.weight =
      typeof this.weight === "string" ? parseInt(this.weight) : this.weight;
    var height = 2.54 * (this.ftHeight * 12 + this.inHeight);
    const BMI_CAT = {
      THIN: "THIN",
      HEALTHY: "HEALTHY",
      OVERWEIGHT: "OVERWEIGHT"
    };
    let response = {};
    if (this.weight > 0 && height > 0) {
      var finalBmi = this.weight / (((height / 100) * height) / 100);
      if (finalBmi < 16.5) {
        response["BMI_CAT"] = BMI_CAT.THIN;
      } else if (finalBmi >= 16.5 && finalBmi <= 32) {
        response["BMI_CAT"] = BMI_CAT.HEALTHY;
      } else if (finalBmi > 32) {
        response["BMI_CAT"] = BMI_CAT.OVERWEIGHT;
      }
      response["BMI"] = finalBmi;
    }

    return response;
  };
}

export const isType = (type, val) =>
  val.constructor.name.toLowerCase() === type.toLowerCase();

export function blockMapper(baseObj, targetObj) {
  Object.keys(targetObj).map((k) => {
    if (baseObj[k] === undefined) {
      baseObj[k] = {};
    }
    if (targetObj[k] && isType("Object", targetObj[k])) {
      blockMapper(baseObj[k], targetObj[k]);
    } else {
      baseObj[k] = targetObj[k];
    }
  });
  return baseObj;
}

export function mapPartialObject(baseObj, partialObj, block) {
  if (block === "nomineeDetails") {
    baseObj[block] = partialObj;
    return baseObj;
  }
  Object.keys(partialObj).forEach((key, i) => {
    baseObj[block][key] =
      partialObj[key] !== undefined ? partialObj[key] : baseObj[block][key];
  });
  return baseObj;
}

export class NomineeUtils {
  constructor(data) {
    this.baseGender = data.gender || "M";
    this.maritalStatus = data.maritalStatus || "SINGLE";
    this.nomineeGender = data.nomineeGender || "M";
  }

  getRelations = () => {
    let relArr = [];
    relArr.push(...NOMINEE_RELATIONSHIP[this.nomineeGender].common);
    if (this.maritalStatus === "MARRIED") {
      relArr = relArr.concat(NOMINEE_RELATIONSHIP[this.nomineeGender].married);
    } else if (
      this.maritalStatus === "WIDOW" ||
      this.maritalStatus === "DIVORCED"
    ) {
      relArr = relArr.concat(NOMINEE_RELATIONSHIP[this.nomineeGender].married);
      relArr = relArr.filter(
        (x) => x.value !== "husband" && x.value !== "wife"
      );
    }
    if (this.baseGender === "M") {
      relArr = relArr.filter((x) => x.value !== "husband");
    }
    if (this.baseGender === "F") {
      relArr = relArr.filter((x) => x.value !== "wife");
    }
    return relArr;
  };
}

export function validateString(label, value) {
  const nameValidationRegEx =
    /^[a-zA-Z]+ [a-zA-Z]+$/.test(value) ||
    /^[a-zA-Z]+ [a-zA-Z]+ [a-zA-Z]+$/.test(value);
  switch (label) {
    case "email":
      var email = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
      return email.test(String(value).toLowerCase());
    case "name":
      return nameValidationRegEx;

    case "name-1word-inclusive":
      return /^[a-zA-Z]+$/.test(value) || nameValidationRegEx;
    case "mobile":
      return /^\d{10}$/.test(value);
    case "mobile-gen":
      return /^\d$/.test(value);
    case "pan":
      return /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/.test(value);
    case "alphaNumericWithSpace":
      return /^[a-zA-Z0-9 ]+$/.test(value);
    case "alphabeticWithSpace":
      return /^[a-zA-Z ]+$/.test(value);
    default:
      return false;
  }
}

export function redactString(str, type) {
  str = str.toString();
  if (type === "pan" || type === "phone") {
    let count = 4;
    return (
      str
        .substring(0, str.length - count)
        .split("")
        .map((c) => {
          return (c = "*");
        })
        .join("") + str.substring(str.length - count, str.length)
    );
  } else if ((type = "email")) {
    let str_strt = str.split("@")[0].split("");
    let str_end = "@" + str.split("@")[1];
    for (var i = 0; i < str_strt.length; i++) {
      if (i % 2 === 1) {
        str_strt[i] = "*";
      }
    }
    return str_strt.join("") + str_end;
  } else return "";
}

export const ValidateSurvivalDate = (pyDate, pyFreq) => {
  var FREQUENCY_TO_MONTHS = {
    annual: 12,
    halfyearly: 6,
    quarterly: 3,
    monthly: 1
  };

  var monthsToAdd = FREQUENCY_TO_MONTHS[pyFreq];
  var currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 1);
  currentDate.setHours(0, 0, 0, 0);
  var maxDate = new Date(currentDate);
  maxDate.setMonth(maxDate.getMonth() + monthsToAdd);
  var year = currentDate.getFullYear();
  var [dayString, monthString] = pyDate.split("/");
  var day = Number(dayString);
  var month = Number(monthString);
  var dateCurrentYear = new Date(year, month - 1, day);
  var dateNextYear = new Date(year + 1, month - 1, day);
  return (
    (dateCurrentYear > currentDate && dateCurrentYear <= maxDate) ||
    (dateNextYear > currentDate && dateNextYear <= maxDate)
  );
};

export const getFreqOptions = (source, product) => {
  var freqArr = freqOptions[product]["regular"];
  if (["HLEMP", "EDM"].includes(source)) {
    freqArr = freqOptions[product]["custom"][source];
  }
  return freqArr;
};

export function inWords(amount) {
  var words = new Array();
  words[0] = "";
  words[1] = "One";
  words[2] = "Two";
  words[3] = "Three";
  words[4] = "Four";
  words[5] = "Five";
  words[6] = "Six";
  words[7] = "Seven";
  words[8] = "Eight";
  words[9] = "Nine";
  words[10] = "Ten";
  words[11] = "Eleven";
  words[12] = "Twelve";
  words[13] = "Thirteen";
  words[14] = "Fourteen";
  words[15] = "Fifteen";
  words[16] = "Sixteen";
  words[17] = "Seventeen";
  words[18] = "Eighteen";
  words[19] = "Nineteen";
  words[20] = "Twenty";
  words[30] = "Thirty";
  words[40] = "Forty";
  words[50] = "Fifty";
  words[60] = "Sixty";
  words[70] = "Seventy";
  words[80] = "Eighty";
  words[90] = "Ninety";
  amount = amount.toString();
  var atemp = amount.split(".");
  var number = atemp[0].split(",").join("");
  var n_length = number.length;
  var words_string = "";
  if (n_length <= 9) {
    var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
    var received_n_array = new Array();
    for (var i = 0; i < n_length; i++) {
      received_n_array[i] = number.substr(i, 1);
    }
    for (var i = 9 - n_length, j = 0; i < 9; i++, j++) {
      n_array[i] = received_n_array[j];
    }
    for (var i = 0, j = 1; i < 9; i++, j++) {
      if (i == 0 || i == 2 || i == 4 || i == 7) {
        if (n_array[i] == 1) {
          n_array[j] = 10 + parseInt(n_array[j]);
          n_array[i] = 0;
        }
      }
    }
    let value = "";
    for (var i = 0; i < 9; i++) {
      if (i == 0 || i == 2 || i == 4 || i == 7) {
        value = n_array[i] * 10;
      } else {
        value = n_array[i];
      }
      if (value != 0) {
        words_string += words[value] + " ";
      }
      if (
        (i == 1 && value != 0) ||
        (i == 0 && value != 0 && n_array[i + 1] == 0)
      ) {
        words_string += "Crores ";
      }
      if (
        (i == 3 && value != 0) ||
        (i == 2 && value != 0 && n_array[i + 1] == 0)
      ) {
        words_string += "Lakhs ";
      }
      if (
        (i == 5 && value != 0) ||
        (i == 4 && value != 0 && n_array[i + 1] == 0)
      ) {
        words_string += "Thousand ";
      }
      if (i == 6 && value != 0 && n_array[i + 1] != 0 && n_array[i + 2] != 0) {
        words_string += "Hundred and ";
      } else if (i == 6 && value != 0) {
        words_string += "Hundred ";
      }
    }
    words_string = words_string.split("  ").join(" ");
  }
  return words_string;
}

export function NumberRange(start, end) {
  return Array(end - start + 1)
    .fill()
    .map((_, idx) => start + idx);
}

export const isSafariIOS =
  Bowser?.parse(window?.navigator?.userAgent)?.browser?.name?.toUpperCase() ===
    "SAFARI" &&
  Bowser?.parse(window?.navigator?.userAgent)?.os?.name?.toUpperCase() ===
    "IOS";

export const LinkOpener = (url) => {
  var link = document.createElement("a");

  document.body.appendChild(link);
  link.style = "display: none";
  link.href = url;
  link.id = "phantom_link";
  link.target = isSafariIOS ? "_self" : "_blank";
  link.rel = "noopener noreferrer";
  link.click();
};

export const isAlphabateAndSingleSpace = (value) =>
  value.match(/^[A-Za-z\s\b]*$/) && !value.includes("  ");

export const _isSingleStringNameInValid = (name) => {
  if (name.length > 30 && !name.trim().includes(" ")) {
    return true;
  } else {
    return false;
  }
};

export const getAgeFromDOB = (DOB) => {
  let age;
  const birthYear = new Date(DOB).getFullYear()
  const birthMonth = new Date(DOB).getMonth() + 1
  const birthDate = new Date(DOB).getDate()
  const currentYear = new Date().getFullYear()
  const currentMonth = new Date().getMonth() + 1
  const currentDate = new Date().getDate()
  age = currentYear - birthYear
  if (birthMonth > currentMonth || (birthMonth === currentMonth && birthDate > currentDate)) age--

  return age
}

export const postSessionEnd = async (product, userId, source) => {
  fetch(PASA_API.SESSION_END(), {
    keepalive: true,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-server-api-key": process.env[
        `REACT_APP_API_KEY_${product.toUpperCase()}_${source.toUpperCase()}`
      ]
    },
    body: JSON.stringify({
      payload: Encrypt({ userId, time: new Date(), product: productMap[product?.toUpperCase()], source: source?.toUpperCase() })
    }),

  }).then(()=>{})
}

export const postSessionStart = async (product, userId, source) => {

  axios.post(PASA_API.SESSION_START(), { userId, time: new Date(), product: productMap[product?.toUpperCase()], source: source?.toUpperCase() })

}

const ivLength = 12, algorithm = 'aes-256-gcm', tagLength = 16, format = 'base64'
export function expiryDecrypt(encryptedData, encryptionKey) {
  const encryptedBuffer = Buffer.from(encryptedData, format)
  const iv = encryptedBuffer.slice(0, ivLength)
  const cipherText = encryptedBuffer.slice(ivLength, -tagLength)
  const tag = encryptedBuffer.slice(-tagLength)
  const decipher = crypto.createDecipheriv(algorithm, encryptionKey, iv)
  decipher.setAuthTag(tag)
  let data = decipher.update(cipherText, format, 'utf8')
  data += decipher.final('utf8')
  return data
}

export const eddSafeBenifitAmount = {
  Lumpsum: 1000000,
  Moneyback: 100000,
  "Early Income": 100000,
  Income: 100000
};

export const getDefaultObject = (obj, product) => {
  if (product === "C2A") {
    if (planDetails?.subOption === "Lumpsum") {
      obj.term = 15;
      obj.ppt = 15;
      obj.benefit = eddSafeBenifitAmount[planDetails?.subOption];
      obj.subOption = "Lumpsum";
    }
    if (planDetails?.subOption === "Moneyback") {
      obj.pyfrequency = "annual";
      obj.SurPercent = 5;
      obj.MoneyBkStruct = "Last 5y";
      obj.term = 20;
      obj.ppt = 10;
      obj.benefit = eddSafeBenifitAmount[planDetails?.subOption];
      obj.Regfreq = "Every 4y";
    }
    if (planDetails?.subOption === "Early Income") {
      obj.pyfrequency = "annual";
      obj.SurPayOutPd = 10;
      obj.defermentPeriod = '8';
      obj.payout_type = "Level";
      // obj.pyIncPercent = 1;
      obj.rop = "Yes";
      obj.ropPercent = "50";
      obj.term = 10;
      obj.ppt = 9;
      obj.benefit = eddSafeBenifitAmount[planDetails?.subOption];
    }
    if (planDetails?.subOption === "Income") {
      obj.pyfrequency = "annual";
      obj.defermentPeriod = '2';
      obj.lifeCover = "Yes";
      obj.SurPayOutPd = 15;
      obj.rop = "Yes";
      obj.ropPercent = "50";
      obj.tmROP = "Maturity";
      obj.payout_type = "Level";
      obj.term = 10;
      obj.ppt = 10;
      obj.pyIncPercent = 1;
      obj.benefit = eddSafeBenifitAmount[planDetails?.subOption];
    }
  }
  if (product === "GWP") {
    if(obj.plan === 'Lump Sum Variant' ){
      // obj.plan = 'Lump Sum Variant'
      obj.frequency = "annual";
      obj.ppt = 10;
      obj.term = 12
      obj.premium = 75000
      obj.pptOption = 'LIMITED'
    }
    if(obj.plan === 'Income Variant' ){
      // obj.plan = 'Lump Sum Variant'
      obj.frequency = "annual";
      obj.pyPeriod = 20,
      obj.pyfrequency = "annual"
      obj.ppt = 6;
      obj.term = 27,
      obj.premium = 75000,
      obj.deathBenefitMultiple = 10

    }


}
if (product === "ESJ") {
    obj["premium"] = 12000;
    obj["ppt"] = 6;
    // obj["plan"] = plan;
    obj['frequency'] = 'annual'
    obj["benefitsCombination"] = '1';
    obj["payoutFrequency"] = 'annual';
    obj["dbMultiple"] = 10;
    obj["survivalBenefitOption"] =  '2';
    obj["bonusOption"] = '3';
    obj["paidUpAddition"] = 'no';
}

  return obj;
};