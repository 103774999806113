import React from "react";
import { NumberRange } from "../utils";
export const freqOptions = {
  ESJ: {
    custom: {
      EDM: [
        { label: "Monthly", value: "monthly" },
        { label: "Quarterly", value: "quarterly" },
        { label: "Half Yearly", value: "halfyearly" },
        { label: "Annual", value: "annual" }
      ],
      HLEMP: [{ label: "Annual", value: "annual" }]
    },
    regular: [
      { label: "Monthly", value: "monthly" },
      { label: "Half Yearly", value: "halfyearly" },
      { label: "Yearly", value: "annual" }
    ]
  },
  GWP: {
    custom: {
      EDM: [
        { label: "Monthly", value: "monthly" },
        { label: "Quarterly", value: "quarterly" },
        { label: "Half Yearly", value: "halfyearly" },
        { label: "Annual", value: "annual" }
      ],
      HLEMP: [{ label: "Annual", value: "annual" }]
    },
    regular: [
      { label: "Monthly", value: "monthly" },
      { label: "Quarterly", value: "quarterly" },
      { label: "Half Yearly", value: "halfyearly" },
      { label: "Annual", value: "annual" },
      { label: "Single", value: "single" }
    ]
  },
  C2A: {
    custom: {
      EDM: [
        { label: "Monthly", value: "monthly" },
        { label: "Quarterly", value: "quarterly" },
        { label: "Half Yearly", value: "halfyearly" },
        { label: "Annual", value: "annual" }
      ],
      HLEMP: [{ label: "Annual", value: "annual" }]
    },
    regular: [
      { label: "Monthly", value: "monthly" },
      { label: "Quarterly", value: "quarterly" },
      { label: "Half Yearly", value: "halfyearly" },
      { label: "Annual", value: "annual" }
    ]
  },
  C2PL: {
    regular: [
      { label: "Quarterly", value: "quarterly" },
      { label: "Half Yearly", value: "halfyearly" },
      { label: "Annual", value: "annual" }
    ],
    custom: {
      EDM: [
        { label: "Monthly", value: "monthly" },
        { label: "Quarterly", value: "quarterly" },
        { label: "Half Yearly", value: "halfyearly" },
        { label: "Annual", value: "annual" }
      ],
      HLEMP: [{ label: "Annual", value: "annual" }]
    }
  },
  SJB: {
    regular: [
      { label: "Monthly", value: "monthly" },
      { label: "Annual", value: "annual" }
    ],
    custom: {
      EDM: [
        { label: "Monthly", value: "monthly" },
        { label: "Annual", value: "annual" }
      ],
      HLEMP: [{ label: "Annual", value: "annual" }]
    }
  },
  C2PP: {
    regular: [
      { label: "Quarterly", value: "quarterly" },
      { label: "Half Yearly", value: "halfyearly" },
      { label: "Annual", value: "annual" }
    ],
    custom: {
      EDM: [
        { label: "Monthly", value: "monthly" },
        { label: "Quarterly", value: "quarterly" },
        { label: "Half Yearly", value: "halfyearly" },
        { label: "Annual", value: "annual" }
      ],
      HLEMP: [{ label: "Annual", value: "annual" }]
    }
  },
  C2P3DP: {
    custom: {
      EDM: [
        { label: "Monthly", value: "monthly" },
        { label: "Quarterly", value: "quarterly" },
        { label: "Half Yearly", value: "halfyearly" },
        { label: "Annual", value: "annual" }
      ],
      HLEMP: [{ label: "Annual", value: "annual" }]
    },
    regular: [
      { label: "Quarterly", value: "quarterly" },
      { label: "Half Yearly", value: "halfyearly" },
      { label: "Annual", value: "annual" }
    ]
  },
  SANCHAY: {
    custom: {
      EDM: [
        { label: "Monthly", value: "monthly" },
        { label: "Quarterly", value: "quarterly" },
        { label: "Half Yearly", value: "halfyearly" },
        { label: "Annual", value: "annual" }
      ],
      HLEMP: [{ label: "Annual", value: "annual" }]
    },
    regular: [
      { label: "Quarterly", value: "quarterly" },
      { label: "Half Yearly", value: "halfyearly" },
      { label: "Annual", value: "annual" }
    ]
  },
  SANCHAYPLUS: {
    custom: {
      EDM: [
        { label: "Monthly", value: "monthly" },
        { label: "Quarterly", value: "quarterly" },
        { label: "Half Yearly", value: "halfyearly" },
        { label: "Annual", value: "annual" },
        { label: "Single", value: "single" }
      ],
      HLEMP: [{ label: "Annual", value: "annual" }]
    },
    regular: [
      { label: "Monthly", value: "monthly" },
      { label: "Quarterly", value: "quarterly" },
      { label: "Half Yearly", value: "halfyearly" },
      { label: "Annual", value: "annual" },
      { label: "Single", value: "single" }
    ]
  },
  SANCHAYPAR: {
    custom: {
      EDM: [
        { label: "Monthly", value: "monthly" },
        { label: "Quarterly", value: "quarterly" },
        { label: "Half Yearly", value: "halfyearly" },
        { label: "Annual", value: "annual" }
      ],
      HLEMP: [{ label: "Annual", value: "annual" }]
    },
    regular: [
      { label: "Monthly", value: "monthly" },
      { label: "Quarterly", value: "quarterly" },
      { label: "Half Yearly", value: "halfyearly" },
      { label: "Annual", value: "annual" }
    ]
  },
  C2PS: {
    regular: [
      { label: "Quarterly", value: "quarterly" },
      { label: "Half Yearly", value: "halfyearly" },
      { label: "Annual", value: "annual" }
    ],
    custom: {
      EDM: [
        { label: "Monthly", value: "monthly" },
        { label: "Quarterly", value: "quarterly" },
        { label: "Half Yearly", value: "halfyearly" },
        { label: "Annual", value: "annual" }
      ],
      HLEMP: [{ label: "Annual", value: "annual" }]
    }
  },
  
};

export const SMQ_FIELDS = [
  //personal questions
  {
    label: "politicallyExposed",
    question: "Are you a politically exposed person?",
    type: "radio",
    options: [
      { value: "NO", text: "NO" },
      { value: "YES", text: "YES" }
    ],
    gender: ["M", "F"],
    alwaysVisible: true,
    products: ["SJB", "C2PL", "SANCHAY", "SANCHAYPAR", "SANCHAYPLUS"],
    category: "personal"
  },
  {
    label: "addictToDrugs",
    question: `Do you consume or have consumed addictives/intoxicating drugs?`,
    type: "radio",
    options: [
      { value: "NO", text: "NO" },
      { value: "YES", text: "YES" }
    ],
    gender: ["M", "F"],
    alwaysVisible: true,
    products: ["SJB", "C2PL", "SANCHAY", "SANCHAYPAR", "SANCHAYPLUS"],
    category: "personal"
  },
  {
    label: "alcoholConsumption",
    question: "Do you consume alcohol?",
    type: "radio",
    options: [
      { value: "NO", text: "NO" },
      { value: "YES", text: "YES" }
    ],
    gender: ["M", "F"],
    alwaysVisible: true,
    products: ["SJB", "C2PL", "SANCHAY", "SANCHAYPAR", "SANCHAYPLUS"],
    subQuestion: [
      {
        label: "alcoholType",
        question: "What alcohol do you consume?",
        type: "radio",
        options: [
          { value: "AT_BEER", text: "BEER" },
          { value: "AT_WINE", text: "WINE" },
          { value: "AT_SPIRIT", text: "SPIRIT" },
          { value: "AT_OTH", text: "OTHERS" }
        ]
      },
      {
        label: "alcoholQuantity",
        question: `<div class=''>
        <ul>
        <li>1 unit of beer = 330mL - 1 pint</li>
        <li>1 unit of wine is 125mL of wine - that is one glass of wine</li>
        <li>1 unit of spirit is 30mL - 1 peg</li>
        <li>1 unit of others is 30 mL </li>
        </ul>
        <div>How much quantity (units) of the selected alcohol do you consume in a week?</div>
        </div>`,
        type: "text",
        options: []
      }
    ],
    category: "personal"
  },
  {
    label: "travelOverseas",
    question:
      "Have you resided overseas for more than 6 months continuously during the last five years, or do you intend to travel overseas in the next six months?",
    type: "radio",
    options: [
      { value: "NO", text: "NO" },
      { value: "YES", text: "YES" }
    ],
    subQuestion: [
      {
        label: "travelOverseasCountry",
        question: "Please provide the country of travel/residence.",
        type: "text",
        options: []
      }
    ],
    gender: ["M", "F"],
    alwaysVisible: true,
    products: ["SJB", "C2PL", "SANCHAY", "SANCHAYPAR", "SANCHAYPLUS"],
    category: "personal"
  },
  {
    label: "previousInsuranceProposal",
    question: `
    Has any of your previous applications for life insurance met with one of the following decisions by the insurer?
    <ol>
    <li>Accepted with extra premium </li>
    <li>Reduction in sum assured </li>
    <li>Declination </li>
    <li>Postponement</li>
    <li>Accepted on other special terms </li>
    <li>Withdrawn by self</li>
    </ol>`,
    type: "radio",
    options: [
      { value: "NO", text: "NO" },
      { value: "YES", text: "YES" }
    ],
    gender: ["M", "F"],
    alwaysVisible: true,
    products: ["SJB", "C2PL", "SANCHAY", "SANCHAYPAR", "SANCHAYPLUS"],
    category: "personal"
  },
  {
    label: "previousHDFCInsurance",
    question:
      "Have you submitted any simultaneous applications for life insurance at any of our offices or to another life insurance company, which is still pending OR are you likely to revive lapsed policies? ",
    type: "radio",
    options: [
      { value: "NO", text: "NO" },
      { value: "YES", text: "YES" }
    ],
    gender: ["M", "F"],
    alwaysVisible: true,
    products: ["SJB", "C2PL", "SANCHAY", "SANCHAYPAR", "SANCHAYPLUS"],
    category: "personal"
  },
  {
    label: "hobbiesNotEngagedIn",
    question: `Do you take part in any hobbies / activities that could be considered dangerous in any way (e.g. hobbies -aviation (other than as a fare-paying passenger), mountaineering, deep sea diving or any form of racing or motor sport) or is your occupation or business, associated with any hazard (exposure to chemical substances / hazardous materials / harmful dust or gases / explosives / working at heights / handling heavy machinery, etc.)?`,
    type: "radio",
    options: [
      { value: "NO", text: "NO" },
      { value: "YES", text: "YES" }
    ],
    gender: ["M", "F"],
    alwaysVisible: true,
    products: ["SJB", "C2PL", "SANCHAY", "SANCHAYPAR", "SANCHAYPLUS"],
    category: "personal"
  },
  {
    label: "convictionHistory",
    question: `Have you ever been or currently being investigated, charge sheeted, prosecuted or convicted or acquittal or having pending charges in respect of any criminal/civil offences in any court of law in India or abroad?`,
    type: "radio",
    options: [
      { value: "NO", text: "NO" },
      { value: "YES", text: "YES" }
    ],
    gender: ["M", "F"],
    alwaysVisible: true,
    products: ["SJB", "C2PL", "SANCHAY", "SANCHAYPAR", "SANCHAYPLUS"],
    category: "personal"
  },

  //health questions
  {
    label: "familyDiagnosedOrExpired",
    question: `Are any of your family members suffering from / have suffered from / have died of Heart Disease or High Blood Pressure or Stroke or Diabetes or Kidney disease or Cancer or HIV/AIDS? `,
    type: "radio",
    options: [
      { value: "NO", text: "NO" },
      { value: "YES", text: "YES" }
    ],
    gender: ["M", "F"],
    alwaysVisible: true,
    products: ["SJB", "C2PL", "SANCHAY", "SANCHAYPAR", "SANCHAYPLUS"],
    category: "health"
  },
  {
    label: "recurrentMedicalCondition",
    question: `Do you suffer from any recurrent/chronic medical condition, any physical disability that affects your everyday activities, physical deformity, illness, or injury that requires continuous medication or has kept you away from work for more than one week in the last 5 years?`,
    type: "radio",
    options: [
      { value: "NO", text: "NO" },
      { value: "YES", text: "YES" }
    ],
    gender: ["M", "F"],
    alwaysVisible: true,
    products: ["SJB", "C2PL", "SANCHAY", "SANCHAYPAR", "SANCHAYPLUS"],
    category: "health"
  },
  {
    label: "familyDiagnosedOrExpired_more-than-25",
    question: `Have two or more of your immediate family members been diagnosed
    with or expired due to any of the following conditions before
    the age of 60?
    <ul>
    <li>Heart disease</li>
    <li>Stroke</li>
    <li>Diabetes</li>
    <li>Kidney disease</li>
    <li>Cancer</li>
    <li>Death due to an unknown cause</li>
    </ul>`,
    type: "radio",
    options: [
      { value: "NO", text: "NO" },
      { value: "YES", text: "YES" }
    ],
    gender: ["M", "F"],
    alwaysVisible: false,
    products: ["SJB", "C2PL", "SANCHAY", "SANCHAYPAR", "SANCHAYPLUS"],
    category: "health"
  },
  {
    label: "hivPhychologicalAilment",
    question: `Have your spouse or you ever been tested positive or are you awaiting test results for HIV/AIDS, Hepatitis B, C, or any sexually transmitted diseases?`,
    type: "radio",
    options: [
      { value: "NO", text: "NO" },
      { value: "YES", text: "YES" }
    ],
    gender: ["M", "F"],
    alwaysVisible: true,
    products: ["SJB", "C2PL", "SANCHAY", "SANCHAYPAR", "SANCHAYPLUS"],
    category: "health"
  },
  {
    label: "currentlyPregnant",
    question: "Are you currently pregnant?",
    type: "radio",
    options: [
      { value: "NO", text: "NO" },
      { value: "YES", text: "YES" }
    ],
    subQuestion: [
      {
        label: "pregnancyWeek",
        question: "How many weeks pregnant are you?",
        type: "text",
        options: []
      }
    ],
    gender: ["F"],
    alwaysVisible: true,
    products: ["SJB", "C2PL", "SANCHAY", "SANCHAYPAR", "SANCHAYPLUS"],
    category: "health"
  },
  {
    label: "genericDiagnosisHistory",
    question: `Have you ever suffered or been diagnosed or been treated for any of the following conditions? (If 'Yes', please encircle the ailment / disease)
    <ol><li>Diabetes / high blood sugar / sugar in urine, high blood pressure, any blood disorder or tuberculosis, or heart disease</li>
    <li>Liver disorder, kidney disorder, disorder of the digestive system, abnormality of the thyroid gland</li>
    <li>Epilepsy, arthritis, or back problem, or stroke, paralysis, or multiple sclerosis</li>
    <li>Dengue, swine flu, respiratory disorders, or encephalitis</li><li>Cancer or Tumour</li>
    <li> Depression or Psychiatric disorder or any Nervous disorder or mental condition or any recurrent medical condition/disability (including eye/ear disorder)</li></ol>`,
    type: "radio",
    options: [
      { value: "NO", text: "NO" },
      { value: "YES", text: "YES" }
    ],
    gender: ["M", "F"],
    alwaysVisible: true,
    products: ["SJB", "C2PL", "SANCHAY", "SANCHAYPAR", "SANCHAYPLUS"],
    category: "health"
  },
  {
    label: "hospitalizationHistory",
    question: `During the last five years, have you undergone or been recommended to undergo any of the following: <ol> <li>Hospitalisation</li><li>Operation</li><li> X ray or any other investigation (excluding medical check-ups for employment/insurance/foreign visit)?</li></ol>`,
    type: "radio",
    options: [
      { value: "NO", text: "NO" },
      { value: "YES", text: "YES" }
    ],
    gender: ["M", "F"],
    alwaysVisible: true,
    products: ["SJB", "C2PL", "SANCHAY", "SANCHAYPAR", "SANCHAYPLUS"],
    category: "health"
  },
  {
    label: "abortionOrHealthRelatedComplications",
    question: `Did you ever go through an abortion, miscarriage, or caesarean section due to pregnancy or other health-related complications, have any diseases in uterus, cervix, or ovaries, have undergone hysterectomy or given birth to a child with any congenital disorder like Down syndrome?`,
    type: "radio",
    options: [
      { value: "NO", text: "NO" },
      { value: "YES", text: "YES" }
    ],
    gender: ["F"],
    alwaysVisible: true,
    products: ["SJB", "C2PL", "SANCHAY", "SANCHAYPAR", "SANCHAYPLUS"],
    category: "health"
  },

  //COVID questions
  {
    label: "covQ1_savings",
    question:
      "Have you travelled outside India in the last 15 days or do you plan to travel outside India during the next 3 months?",
    type: "radio",
    options: [
      { value: "NO", text: "NO" },
      { value: "YES", text: "YES" }
    ],
    gender: ["M", "F"],
    alwaysVisible: true,
    products: ["SANCHAY", "SANCHAYPAR", "SANCHAYPLUS"],
    category: "covid"
  },
  {
    label: "covQ2_savings",
    question:
      "Have you been tested positive for COVID-19<sup>*</sup> or are awaiting results of such a test or been advised to be under quarantine due to COVID-19<sup>*</sup> ?",
    type: "radio",
    options: [
      { value: "NO", text: "NO" },
      { value: "YES", text: "YES" }
    ],
    gender: ["M", "F"],
    alwaysVisible: true,
    products: ["SANCHAY", "SANCHAYPAR", "SANCHAYPLUS"],
    category: "covid"
  },
  {
    label: "covQ3_savings",
    question: `Are you currently suffering from or in the last 1 month, have suffered from fever, persistent dry cough, sore throat, breathing difficulties, body pain, fatigue, conjunctivitis, gastro-intestinal symptoms (vomiting/diarrhoea) or been in contact with an individual suspected or confirmed to have COVID-19<sup>*</sup> ?`,
    type: "radio",
    options: [
      { value: "NO", text: "NO" },
      { value: "YES", text: "YES" }
    ],
    gender: ["M", "F"],
    alwaysVisible: true,
    products: ["SANCHAY", "SANCHAYPAR", "SANCHAYPLUS"],
    category: "covid"
  },
  {
    label: "covQ1_term",
    question: `Have you travelled outside India in the last 15 days or do you plan to travel outside India during the next 3 months?`,
    type: "radio",
    options: [
      { value: "NO", text: "NO" },
      { value: "YES", text: "YES" }
    ],
    gender: ["M", "F"],
    alwaysVisible: false,
    products: ["C2PL", "SJB"],
    category: "covid"
  },
  {
    label: "covQ2_term",
    question: `Have you been tested positive for COVID-19<sup>*</sup> or are awaiting results of such a test?`,
    type: "radio",
    options: [
      { value: "NO", text: "NO" },
      { value: "YES", text: "YES" }
    ],
    gender: ["M", "F"],
    alwaysVisible: false,
    products: ["C2PL", "SJB", "C2PS"],
    category: "covid"
  },
  {
    label: "covQ3_term",
    question: `Are you currently suffering from or in the last 1 month, have suffered from fever, persistent dry cough, sore throat, breathing difficulties, body pain, fatigue, conjunctivitis,gastro-intestinal symptoms (vomiting/diarrhoea) or been in contact with an individual suspected or confirmed to have COVID-19<sup>*</sup> ?`,
    type: "radio",
    options: [
      { value: "NO", text: "NO" },
      { value: "YES", text: "YES" }
    ],
    gender: ["M", "F"],
    alwaysVisible: false,
    products: ["C2PL", "SJB"],
    category: "covid"
  },
  {
    label: "covQ4_term",
    question: `Are you a Healthcare professional, volunteer or enrolled as a Corona virus Warrior in hospital/ clinic with COVID-19<sup>*</sup> facility and/ or treating/ in contact with COVID-19<sup>*</sup> infected individuals or contaminated material?`,
    type: "radio",
    options: [
      { value: "NO", text: "NO" },
      { value: "YES", text: "YES" }
    ],
    gender: ["M", "F"],
    alwaysVisible: false,
    products: ["C2PL", "SJB"],
    category: "covid"
  },
  {
    label: "covQ5_term",
    question: `Have you been vaccinated for COVID-19<sup>*</sup> ?`,
    type: "radio",
    options: [
      { value: "YES", text: "YES" },
      { value: "NO", text: "NO" }
    ],
    gender: ["M", "F"],
    alwaysVisible: false,
    products: ["C2PL", "SJB", "C2PS"],
    category: "covid",
    subQuestion: [
      {
        label: "dose1Date",
        question: "Date of administration of the 1<sup>st</sup> dose",
        type: "date"
      },
      {
        label: "dose2Date",
        question: "Date of administration of the 2<sup>nd</sup> dose",
        type: "date"
      },
      {
        label: "boosterDoseDate",
        question: "Date of administration of the booster dose (Optional)",
        type: "date"
      },
      {
        label: "vaccineName",
        question: "Name of  the vaccine",
        type: "select",
        options: [
          "Covaxin",
          "Covishield",
          "Sputnik",
          "Sinopharm",
          "Sinovac",
          "Moderna",
          "Janssen",
          "Others*"
        ]
      },
      {
        label: "vaccineAdverseEffects",
        question: "Did you experience any adverse reaction post vaccination?",
        type: "radio",
        options: [
          { value: "YES", text: "YES" },
          { value: "NO", text: "NO" }
        ]
      }
    ]
  },
  {
    label: "covQ6_term",
    question: `Were you ever hospitalised for Covid infection or its complication or do you have any ongoing complications related to covid Infection?`,
    type: "radio",
    options: [
      { value: "NO", text: "NO" },
      { value: "YES", text: "YES" }
    ],
    gender: ["M", "F"],
    alwaysVisible: true,
    products: ["C2PL", "SJB", "C2PS"],
    category: "covid"
  }
];

export const declarationTemplate = {
  abortionOrHealthRelatedComplications: "",
  addictToDrugs: "",
  alcoholConsumption: "",
  alcoholQuantity: 0,
  alcoholType: "",
  convictionHistory: "",
  covQ1_savings: "",
  covQ2_savings: "",
  covQ3_savings: "",
  covQ1_term: "",
  covQ2_term: "",
  covQ3_term: "",
  covQ4_term: "",
  covQ5_term: "",
  covQ6_term: "",
  currentlyPregnant: "",
  familyDiagnosedOrExpired: "",
  genericDiagnosisHistory: "",
  hivPhychologicalAilment: "",
  hobbiesNotEngagedIn: "",
  hospitalizationHistory: "",
  politicallyExposed: "",
  pregnancyWeek: 0,
  previousHDFCInsurance: "",
  previousInsuranceProposal: "",
  recurrentMedicalCondition: "",
  travelOverseas: "",
  travelOverseasCountry: ""
};

export const COUNTRIES = [
  { name: "Afghanistan", index: 1, valid: false, nriValid: false },
  { name: "Albania", index: 2, valid: true, nriValid: true },
  { name: "Alderney", index: 3, valid: false, nriValid: false },
  { name: "Algeria", index: 4, valid: false, nriValid: false },
  { name: "Andorra", index: 5, valid: true, nriValid: true },
  { name: "Angola", index: 6, valid: false, nriValid: false },
  { name: "Anguilla", index: 7, valid: false, nriValid: false },
  { name: "Antigua and Barbuda", index: 8, valid: true, nriValid: true },
  { name: "Argentina", index: 9, valid: true, nriValid: true },
  { name: "Armenia", index: 10, valid: false, nriValid: false },
  { name: "Aruba", index: 11, valid: false, nriValid: false },
  { name: "Australia", index: 12, valid: true, nriValid: true },
  { name: "Austria", index: 13, valid: true, nriValid: true },
  { name: "Azerbaijan", index: 14, valid: false, nriValid: false },
  { name: "Bahamas", index: 15, valid: true, nriValid: false },
  { name: "Bahrain", index: 16, valid: true, nriValid: true },
  { name: "Bangladesh", index: 17, valid: false, nriValid: false },
  { name: "Barbados", index: 18, valid: true, nriValid: true },
  { name: "Belarus", index: 19, valid: true, nriValid: false },
  { name: "Belgium", index: 20, valid: true, nriValid: true },
  { name: "Belize", index: 21, valid: true, nriValid: false },
  { name: "Benin", index: 22, valid: true, nriValid: false },
  { name: "Bermuda", index: 23, valid: false, nriValid: false },
  { name: "Bhutan", index: 24, valid: true, nriValid: true },
  { name: "Bolivia", index: 25, valid: true, nriValid: true },
  { name: "Bosnia and Herzegovina", index: 26, valid: true, nriValid: true },
  { name: "Botswana", index: 27, valid: true, nriValid: false },
  { name: "Brazil", index: 28, valid: true, nriValid: true },
  {
    name: "British  Virgin  Islands",
    index: 30,
    valid: false,
    nriValid: false
  },
  { name: "Brunei", index: 31, valid: true, nriValid: true },
  { name: "Bulgaria", index: 32, valid: true, nriValid: true },
  { name: "Burkina Faso", index: 33, valid: false, nriValid: false },
  { name: "Burma", index: 34, valid: false, nriValid: false },
  { name: "Burundi", index: 35, valid: false, nriValid: false },
  { name: "Cambodia", index: 36, valid: true, nriValid: true },
  { name: "Cameroon", index: 37, valid: false, nriValid: false },
  { name: "Canada", index: 38, valid: true, nriValid: true },
  { name: "Canary Islands", index: 39, valid: false, nriValid: false },
  { name: "Cape Verde", index: 41, valid: true, nriValid: true },
  { name: "Cayman Islands", index: 42, valid: false, nriValid: false },
  {
    name: "Central African Republic",
    index: 43,
    valid: false,
    nriValid: false
  },
  { name: "Chad", index: 44, valid: false, nriValid: false },
  { name: "Chile", index: 45, valid: true, nriValid: true },
  { name: "China", index: 46, valid: false, nriValid: false },
  { name: "Colombia", index: 47, valid: false, nriValid: false },
  { name: "Comoros", index: 48, valid: true, nriValid: true },
  { name: "Congo", index: 49, valid: false, nriValid: false },
  { name: "Costa Rica", index: 50, valid: true, nriValid: true },
  { name: "Cote dIvoire", index: 51, valid: false, nriValid: false },
  { name: "Croatia", index: 52, valid: true, nriValid: true },
  { name: "Cuba", index: 53, valid: true, nriValid: true },
  { name: "Curacao", index: 54, valid: false, nriValid: false },
  { name: "Cyprus", index: 55, valid: true, nriValid: true },
  { name: "Czech", index: 56, valid: true, nriValid: true },
  {
    name: "Democratic Republic of Congo",
    index: 58,
    valid: false,
    nriValid: false
  },
  { name: "Denmark", index: 59, valid: true, nriValid: true },
  { name: "Djibouti", index: 60, valid: false, nriValid: false },
  { name: "Dominica", index: 61, valid: true, nriValid: true },
  { name: "Dominican Republic", index: 62, valid: true, nriValid: true },
  { name: "East Africa", index: 63, valid: false, nriValid: false },
  { name: "East Timor", index: 64, valid: false, nriValid: false },
  { name: "Ecuador", index: 65, valid: false, nriValid: false },
  { name: "Egypt", index: 66, valid: false, nriValid: false },
  { name: "El Salvador", index: 67, valid: true, nriValid: true },
  { name: "England", index: 68, valid: true, nriValid: false },
  { name: "Equitorial Guinea", index: 69, valid: false, nriValid: false },
  { name: "Eritrea", index: 70, valid: false, nriValid: false },
  { name: "Estonia", index: 71, valid: true, nriValid: true },
  { name: "Ethiopia", index: 72, valid: false, nriValid: false },
  { name: "Eswatini", index: 72, valid: true, nriValid: false },
  { name: "Falkland Islands", index: 73, valid: false, nriValid: false },
  { name: "Fiji", index: 74, valid: true, nriValid: true },
  { name: "Finland", index: 75, valid: true, nriValid: true },
  { name: "France", index: 76, valid: true, nriValid: false },
  { name: "French Guiana", index: 77, valid: false, nriValid: false },
  { name: "French Polynesia", index: 78, valid: false, nriValid: false },
  { name: "Gabon", index: 79, valid: true, nriValid: false },
  { name: "Gambia", index: 80, valid: false, nriValid: false },
  { name: "Gambia/ The Gambia", index: 81, valid: false, nriValid: false },
  { name: "Georgia", index: 82, valid: false, nriValid: false },
  { name: "Germany", index: 83, valid: true, nriValid: false },
  { name: "Ghana", index: 84, valid: false, nriValid: false },
  { name: "Gibraltar", index: 85, valid: false, nriValid: false },
  { name: "Great Britain", index: 86, valid: false, nriValid: false },
  { name: "Greece", index: 87, valid: true, nriValid: true },
  { name: "Greenland", index: 88, valid: false, nriValid: false },
  { name: "Grenada", index: 89, valid: true, nriValid: true },
  { name: "Guadeloupe", index: 90, valid: false, nriValid: false },
  { name: "Guam", index: 91, valid: false, nriValid: false },
  { name: "Guatemala", index: 92, valid: false, nriValid: false },
  { name: "Guinea", index: 93, valid: false, nriValid: false },
  { name: "Guinea bissau", index: 94, valid: false, nriValid: false },
  { name: "Guyana", index: 95, valid: true, nriValid: false },
  { name: "Haiti", index: 96, valid: false, nriValid: false },
  { name: "Honduras", index: 97, valid: false, nriValid: false },
  { name: "Hong Kong", index: 98, valid: false, nriValid: false },
  { name: "Hungary", index: 99, valid: true, nriValid: true },
  { name: "Iceland", index: 100, valid: true, nriValid: true },
  { name: "India", index: 101, valid: true, nriValid: true },
  { name: "Indonesia", index: 102, valid: false, nriValid: false },
  { name: "Iran", index: 103, valid: false, nriValid: false },
  { name: "Iraq", index: 104, valid: false, nriValid: false },
  { name: "Ireland", index: 105, valid: true, nriValid: true },
  { name: "Israel", index: 106, valid: false, nriValid: false },
  { name: "Italy", index: 107, valid: false, nriValid: false },
  { name: "Ivory Coast", index: 108, valid: false, nriValid: false },
  { name: "Jamaica", index: 109, valid: true, nriValid: false },
  { name: "Japan", index: 110, valid: false, nriValid: true },
  { name: "Jordan", index: 111, valid: true, nriValid: true },
  { name: "Kazhakistan", index: 112, valid: false, nriValid: false },
  { name: "Kenya", index: 113, valid: false, nriValid: false },
  { name: "Kiribati", index: 114, valid: true, nriValid: true },
  { name: "Kosovo", index: 115, valid: true, nriValid: true },
  { name: "Kuwait", index: 115, valid: true, nriValid: true },
  { name: "Kyrgyzstan", index: 116, valid: false, nriValid: false },
  { name: "Laos", index: 117, valid: true, nriValid: true },
  { name: "Latvia", index: 118, valid: true, nriValid: true },
  { name: "Lebanon", index: 119, valid: false, nriValid: false },
  { name: "Lesotho", index: 120, valid: true, nriValid: false },
  { name: "Liberia", index: 121, valid: false, nriValid: false },
  { name: "Libya", index: 122, valid: false, nriValid: false },
  { name: "Liechtenstein", index: 123, valid: true, nriValid: true },
  { name: "Lithuania", index: 124, valid: true, nriValid: true },
  { name: "Luxembourg", index: 126, valid: true, nriValid: true },
  { name: "Macau", index: 127, valid: false, nriValid: false },
  { name: "Macedonia", index: 128, valid: false, nriValid: false },
  { name: "Madagascar", index: 129, valid: true, nriValid: true },
  { name: "Madeira", index: 130, valid: false, nriValid: false },
  { name: "Malawi", index: 131, valid: true, nriValid: false },
  { name: "Malaysia", index: 132, valid: false, nriValid: true },
  { name: "Maldives", index: 133, valid: true, nriValid: true },
  { name: "Mali", index: 134, valid: false, nriValid: false },
  { name: "Malta", index: 135, valid: true, nriValid: true },
  { name: "Marshall Islands", index: 136, valid: true, nriValid: true },
  { name: "Martinique", index: 137, valid: false, nriValid: false },
  { name: "Mauritania", index: 138, valid: false, nriValid: false },
  { name: "Mauritius", index: 139, valid: true, nriValid: true },
  { name: "Mexico", index: 140, valid: false, nriValid: false },
  { name: "Micronesia", index: 141, valid: true, nriValid: true },
  { name: "Moldova", index: 142, valid: false, nriValid: false },
  { name: "Monaco", index: 143, valid: true, nriValid: true },
  { name: "Mongolia", index: 144, valid: true, nriValid: true },
  { name: "Montenegro", index: 145, valid: true, nriValid: true },
  { name: "Monteserrat", index: 146, valid: false, nriValid: false },
  { name: "Morocco", index: 147, valid: false, nriValid: false },
  { name: "Mozambique", index: 148, valid: false, nriValid: false },
  { name: "Myanmar", index: 149, valid: false, nriValid: false },
  { name: "Namibia", index: 150, valid: true, nriValid: false },
  { name: "Nauru", index: 151, valid: true, nriValid: true },
  { name: "Nepal", index: 152, valid: false, nriValid: false },
  { name: "Netherlands", index: 153, valid: true, nriValid: true },
  { name: "New Caledonia", index: 154, valid: false, nriValid: false },
  { name: "New Zealand", index: 155, valid: true, nriValid: true },
  { name: "Nicaragua", index: 156, valid: true, nriValid: true },
  { name: "Niger", index: 157, valid: false, nriValid: false },
  { name: "Nigeria", index: 158, valid: false, nriValid: false },
  { name: "Niue", index: 159, valid: false, nriValid: false },
  { name: "North Korea", index: 160, valid: false, nriValid: false },
  { name: "North Macedonia", index: 161, valid: true, nriValid: true },
  { name: "Norway", index: 162, valid: true, nriValid: true },
  { name: "Oman", index: 163, valid: true, nriValid: true },
  { name: "Other", index: 164, valid: false, nriValid: false },
  { name: "Pakistan", index: 165, valid: false, nriValid: false },
  { name: "Palau", index: 166, valid: true, nriValid: true },
  { name: "Palestine", index: 167, valid: false, nriValid: false },
  { name: "Panama", index: 168, valid: true, nriValid: true },
  { name: "Papua New guinea", index: 169, valid: false, nriValid: false },
  { name: "Paraguay", index: 170, valid: false, nriValid: false },
  { name: "Peru", index: 171, valid: false, nriValid: false },
  { name: "Philippines", index: 172, valid: false, nriValid: false },
  { name: "Poland", index: 173, valid: true, nriValid: true },
  { name: "Portugal", index: 174, valid: true, nriValid: true },
  { name: "Puerto Rico", index: 175, valid: false, nriValid: false },
  { name: "Qatar", index: 176, valid: true, nriValid: true },
  { name: "Republic of Congo", index: 177, valid: false, nriValid: false },
  { name: "Republic of Korea", index: 178, valid: false, nriValid: false },
  { name: "Reunion", index: 179, valid: false, nriValid: false },
  { name: "Romania", index: 180, valid: true, nriValid: true },
  { name: "Russia", index: 182, valid: false, nriValid: false },
  { name: "Rwanda", index: 183, valid: false, nriValid: false },
  { name: "Samoa", index: 184, valid: true, nriValid: true },
  { name: "San Marino", index: 185, valid: true, nriValid: true },
  { name: "Sao Tome and Principe", index: 186, valid: true, nriValid: true },
  { name: "Saudi Arabia", index: 187, valid: true, nriValid: true },
  { name: "Senegal", index: 189, valid: false, nriValid: false },
  { name: "Serbia", index: 190, valid: true, nriValid: true },
  { name: "Seychelles", index: 191, valid: true, nriValid: true },
  { name: "Sierra Leone", index: 192, valid: false, nriValid: false },
  { name: "Singapore", index: 193, valid: false, nriValid: true },
  { name: "Slovakia", index: 194, valid: true, nriValid: true },
  { name: "Slovenia", index: 195, valid: true, nriValid: true },
  { name: "Solomon Islands", index: 196, valid: true, nriValid: true },
  { name: "Somalia", index: 197, valid: false, nriValid: false },
  { name: "South Africa", index: 198, valid: true, nriValid: false },
  { name: "South Korea", index: 199, valid: false, nriValid: true },
  { name: "Spain", index: 200, valid: true, nriValid: false },
  { name: "Sri Lanka", index: 201, valid: false, nriValid: false },
  { name: "St Kitts and Nevis", index: 202, valid: true, nriValid: true },
  { name: "St Lucia", index: 203, valid: true, nriValid: true },
  { name: "St Martin", index: 204, valid: false, nriValid: false },
  {
    name: "St Vincent and Grenadines",
    index: 205,
    valid: true,
    nriValid: true
  },
  { name: "Sudan", index: 206, valid: false, nriValid: false },
  { name: "Suriname", index: 207, valid: true, nriValid: false },
  { name: "Swansea", index: 208, valid: false, nriValid: false },
  { name: "Sweden", index: 210, valid: true, nriValid: true },
  { name: "Switzerland", index: 211, valid: true, nriValid: true },
  { name: "Syria", index: 212, valid: false, nriValid: false },
  { name: "Tahiti", index: 213, valid: false, nriValid: false },
  { name: "Taiwan", index: 214, valid: true, nriValid: true },
  { name: "Tajikistan", index: 215, valid: false, nriValid: false },
  { name: "Tanzania", index: 216, valid: true, nriValid: false },
  { name: "Thailand", index: 217, valid: false, nriValid: false },
  { name: "Tibet", index: 218, valid: false, nriValid: false },
  { name: "Togo", index: 219, valid: false, nriValid: false },
  { name: "Tokelau", index: 220, valid: false, nriValid: false },
  { name: "Tonga", index: 221, valid: true, nriValid: true },
  { name: "Trinidad and Tobago", index: 222, valid: true, nriValid: false },
  { name: "Tunisia", index: 223, valid: true, nriValid: true },
  { name: "Turkey", index: 224, valid: false, nriValid: false },
  { name: "Turkmenistan", index: 225, valid: true, nriValid: true },
  { name: "Turks and Caicos", index: 226, valid: false, nriValid: false },
  { name: "Tuvalu", index: 227, valid: true, nriValid: true },
  { name: "Uganda", index: 228, valid: false, nriValid: false },
  { name: "Ukraine", index: 229, valid: false, nriValid: false },
  { name: "United Arab Emirates", index: 230, valid: true, nriValid: true },
  { name: "United Kingdom", index: 231, valid: true, nriValid: false },
  {
    name: "United States of America",
    index: 232,
    valid: true,
    nriValid: false
  },
  { name: "US Virgin Islands", index: 233, valid: false, nriValid: false },
  { name: "Uruguay", index: 234, valid: true, nriValid: true },
  { name: "Uzbekistan", index: 235, valid: false, nriValid: false },
  { name: "Vanuatu", index: 236, valid: true, nriValid: true },
  { name: "Vatican", index: 237, valid: true, nriValid: false },
  { name: "Venezuela - Caracas", index: 238, valid: false, nriValid: false },
  { name: "Vietnam", index: 239, valid: false, nriValid: true },
  { name: "West Africa", index: 240, valid: false, nriValid: false },
  { name: "Western Sahara", index: 241, valid: false, nriValid: false },
  { name: "Yemen", index: 242, valid: false, nriValid: false },
  { name: "Yugoslavia", index: 243, valid: false, nriValid: false },
  { name: "Zaire", index: 244, valid: false, nriValid: false },
  { name: "Zambia", index: 245, valid: true, nriValid: false },
  { name: "Zimbabwe", index: 246, valid: false, nriValid: false }
];

export const user_drop_map = {
  politicallyExposed: "Customer politically exposed",
  travelOverseas: "Customer plans to travel to a high risk country",
  alcoholConsumption: "High alcohol consumption",
  hobbiesNotEngagedIn: "Hobbies involve risk",
  addictToDrugs: "Addict to substances",
  previousInsuranceProposal: "Risk in previous insurance proposal",
  convictionHistory: "Conviction history",
  currentlyPregnant: "Customer currently pregnant",
  abortionOrHealthRelatedComplications:
    "History of abortion/miscarriage/c-section",
  familyDiagnosedOrExpired: "History of heart diseases in family",
  recurrentMedicalCondition: "Chronic medical condition / disability",
  hivPhychologicalAilment:
    "HIV/AIDS, Hepatitis B, C, or any sexually transmitted diseases",
  hospitalizationHistory: "History of hospitalization",
  genericDiagnosisHistory: "History of generic health conditions",
  previousHDFCInsurance: "Existing hdfc insurance proposal",
  apiRejection: "Customer at high risk",
  bmiRisk: "BMI risk",
  nri: "NRI Cutomer",
  covQ1_savings: "covid19 case",
  covQ2_savings: "covid19 case",
  covQ3_savings: "covid19 case",
  covQ1_term: "covid19 case",
  covQ2_term: "covid19 case",
  covQ3_term: "covid19 case",
  covQ4_term: "covid19 case",
  covQ5_term: "covid19 case",
  covQ6_term: "covid19 case",
  industryType: "risk in industry/desgination"
};

export const NOMINEE_DATA = {
  title: "",
  name: "",
  relationship: "",
  gender: "M",
  dob: "",
  entitlementPercentage: ""
};

export const USER_TITLE = {
  M: [
    { name: "KUMAR", value: "kumar" },
    { name: "MR.", value: "mr" },
    { name: "SHRI", value: "shri" },
    { name: "DR.", value: "dr" }
  ],
  F: [
    { name: "DR.", value: "dr" },
    { name: "KUMARI", value: "kumari" },
    { name: "MISS", value: "miss" },
    { name: "MRS.", value: "mrs" },
    { name: "MS.", value: "ms" },
    { name: "SMT", value: "smt" }
  ]
};

export const NOMINEE_RELATIONSHIP = {
  M: {
    common: [{ name: "Father", value: "father" }],
    married: [
      { name: "Husband", value: "husband" },
      { name: "Son", value: "son" }
    ]
  },
  F: {
    common: [{ name: "Mother", value: "mother" }],
    married: [
      { name: "Wife", value: "wife" },
      { name: "Daughter", value: "daughter" }
    ]
  }
};

export const OFFER_DESCRIPTION = {
  SANCHAYPAR: (
    <div>
      <div className="notch"></div>
      <div className="prd-label">Why you need this Plan ? </div>
      <div className="prd-sub-label">
        A non-linked, participating life insurance plan that helps you create a
        legacy for your family
      </div>
      <div className="prd-desc">
        <ul>
          <li>Life cover with protection up to age 100 years</li>
          <li>
            Choose between Immediate Income or Deferred Income options as per
            your needs
          </li>
          <li>
            Flexibility to accrue<sup>*</sup> the survival benefit payouts
          </li>
          <li>
            Enhanced benefit for policies with Annual Premium more than or equal
            to INR 100,000<sup>**</sup>
          </li>
          <li>
            Tax benefits<sup>^</sup>{" "}
          </li>
        </ul>
        <div className="small-note-text">
          <sup>*</sup>You have an option to defer the Survival Benefit(s),
          arising out of declared Cash Bonuses and/ or Guaranteed Income and
          accrue them instead. The accrued payouts will be accumulated monthly
          at Reverse Repo Rate published by RBI and will be reviewed at the
          beginning of every month.
        </div>
        <div className="small-note-text">
          <sup>^</sup>As per Income Tax, 1961. Tax benefits are subject to
          changes in tax laws.
        </div>
        <div className="small-note-text">
          <sup>**</sup>Applicable only to Deferred Income plan option. For more
          details, please refer “Additional Benefit for High Premium Policies”
          section in the Sales brochure.
        </div>
      </div>
    </div>
  ),
  SANCHAY: (
    <div>
      <div className="notch"></div>
      <div className="prd-label">Why you need this Plan ? </div>
      <div className="prd-sub-label">
        A non-participating insurance plan that offers guaranteed benefits
      </div>
      <div className="prd-desc">
        <ul>
          <li>
            <b>Flexibility</b> – Premium payment options of 5, 6, 8 and 10 years
            or pay once under Single Pay
          </li>

          <li>
            Choose policy terms from 5 to 20 years for Single Pay or 10, 12 and
            15 years to 40 years for Limited Pay option
          </li>

          <li>
            <b>Benefits</b> - Guaranteed Additions of 8% or 9% of Sum Assured on
            Maturity to your maturity benefit, accrued at simple rate for each
            completed policy year
          </li>
          <li>
            The total Guaranteed Maturity Benefit will be between 140% to 460%
            of Sum Assured on Maturity provided all due premiums have been paid
          </li>
        </ul>
      </div>
    </div>
  ),
  C2PS: (
    <div>
      <div className="notch"></div>
      <div className="prd-label">Why you need this Plan ? </div>
      <div className="prd-sub-label">
        A Non-Linked, Non-Participating, Individual, Pure Risk Premium/ Savings
        Life Insurance Plan
      </div>
      <div className="prd-desc">
        <ul>
          <li>Provides comprehensive financial protection to your family</li>
          <li>
            Get back all premiums paid on survival till maturity with Return of
            Premium option
          </li>
          <li>
            Additional amount payable in case of accidental death during policy
          </li>
          <li>
            Option to choose increasing Death Benefit upto 200% under Life
            Option
          </li>
        </ul>
      </div>
    </div>
  ),
  SANCHAYPLUS: (
    <div>
      <div className="notch"></div>
      <div className="prd-label">Why you need this Plan ? </div>
      <div className="prd-sub-label">
        A non-participating, non-linked, savings insurance plan
      </div>
      <div className="prd-desc">
        <ul>
          <li>
            Guaranteed<sup>^</sup> benefits{" "}
          </li>
          <li>
            Flexibility to choose how you want to get your Guaranteed
            <sup>^</sup> benefits: as a lump sum or as regular income
          </li>
          <li>
            Life Long Income option – Guaranteed<sup>^</sup> Income till age 99
            years
          </li>
          <li>
            Long Term Income option – Guaranteed<sup>^</sup> Income for a fixed
            term of 25 to 30 years
          </li>
          <li>
            Life Long and Long Term Income options return the total premiums
            paid<sup>*</sup> at the end of payout period
          </li>
          <li>
            Enhanced benefit for policies with Annual Premium more than INR
            150,000<sup>**</sup>
          </li>
          <li>
            Enhance your protection coverage with additional protection options
            at a small extra premium
          </li>
        </ul>
        <div className="small-note-text">
          <sup>^</sup>Provided all due premiums have been paid and the policy is
          in force.
        </div>
        <div className="small-note-text">
          <sup>*</sup>Total Premiums Paid means total of all the premiums
          received, excluding any extra premium, any rider premium, and taxes.
        </div>
        <div className="small-note-text">
          <sup>**</sup>For more details, please refer “Enhanced Benefit for High
          Premium Policies” section in the Sales brochure.
        </div>
      </div>
    </div>
  ),
  C2PL: (
    <div>
      <div className="notch"></div>
      <div className="prd-label">Why you need this Plan ? </div>
      <div className="prd-sub-label">
        A Non-Linked, Non-Participating, Individual, Pure Risk Premium/ Savings
        Life Insurance Plan
      </div>
      <div className="prd-desc">
        <ul>
          <li>Provides comprehensive financial protection to your family</li>
          <li>
            Option to avail cover for Whole of Life under Life Protect option
          </li>
          <li>
            Option to reduce Premium Payment Term from Regular Pay to Limited
            Pay{" "}
          </li>
          <li>Special premium rates for female lives and non-tobacco users</li>
          <li>
            Tax benefits as per Income Tax Act, 1961. Tax benefits are subject
            to changes in tax laws.
          </li>
        </ul>
      </div>
    </div>
  ),
  SJB: (
    <div>
      <div className="notch"></div>
      <div className="prd-label">Why you need this Plan ? </div>
      <div className="prd-sub-label">
        A Non-Linked, Non-Participating, Individual, Pure Risk Premium/ Savings
        Life Insurance Plan
      </div>
      <div className="prd-desc">
        <ul>
          <li>Provides comprehensive financial protection to your family</li>
          <li>
            Option to avail cover for Whole of Life under Life Protect option
          </li>
          <li>
            Option to reduce Premium Payment Term from Regular Pay to Limited
            Pay{" "}
          </li>
          <li>Special premium rates for female lives and non-tobacco users</li>
          <li>
            Tax benefits as per Income Tax Act, 1961. Tax benefits are subject
            to changes in tax laws.
          </li>
        </ul>
      </div>
    </div>
  ),
  C2A: (
    <div>
      <div className="notch"></div>
      <div className="prd-label">Why you need this Plan ? </div>
      <div className="prd-sub-label">
        A Non-Linked, Non-Participating, Individual Savings Life Insurance Plan
      </div>
      <div className="prd-desc">
        <ul>
          <li>
            Tailor your plan with customizable options for Lump Sum, periodic
            income, or money-back features
          </li>
          <li>
            Choose the desired Death Benefit Multiple that suits your
            preferences
          </li>
          <li>
            Enjoy a Guaranteed Immediate Income with the freedom to select the
            duration of your choice according to specific requirements
          </li>
          <li>
            Flexibility on Guaranteed benefits as a lump sum or as regular
            income
          </li>
          <li>
            Premium Offset Feature to your premium against survival benefit
            payout
          </li>
          <div className="small-note-text" style={{ marginTop: "12px" }}>
            <sup>*</sup>You have an option to defer the Survival Benefit(s),
            arising out of declared Cash Bonuses and/ or Guaranteed Income and
            accrue them instead. The accrued payouts will be accumulated monthly
            at Reverse Repo Rate published by RBI and will be reviewed at the
            beginning of every month.
          </div>
        </ul>
      </div>
    </div>
  ),
  GWP: (
    <div>
      <div className="notch"></div>
      <div className="prd-label">Why you need this Plan ? </div>
      <div className="prd-sub-label">
        A Non-Linked, Non-Participating Individual Life Insurance Savings Plan
      </div>
      <div className="prd-desc">
        <ul>
          <li>
            Pay once or for a limited term, enjoy Life insurance cover during
            full policy term
          </li>
          <li>
            Guaranteed Benefits# on survival in case Income variant is opted
          </li>
          <li>
            Choice to take guaranteed benefit as lump sum at maturity or as
            Regular Income and lump sum both
          </li>
        </ul>
      </div>
    </div>
  ),
  ESJ: (
    <div>
      <div className="notch"></div>
      <div className="prd-label">Why you need this Plan ? </div>
      <div className="prd-sub-label">
        A unique Life Insurance cum savings plan which provides financial
        protection to your family in your absence.
      </div>
      <div className="prd-desc">
        <ul>
          <li>
            Pay Premium for a limited term, enjoy Life Insurance Cover for
            entire Policy Term
          </li>
          <li>Option to choose Guaranteed Income Benefit</li>
          <li>Flexible options to avail Income Payouts</li>
          <li>
            Flexible options to avail potential upside of benefits through
            bonuses (if declared)
          </li>
        </ul>
      </div>
    </div>
  ),

};

const _isAbove40 = (age) => {
  return Number(age) > 40 ? true : false;
};

export const c2psPlans = {
  Life: {
    term: (age) => {
      return {
        SINGLE: {
          range: [5, _isAbove40(85 - Number(age)) ? 40 : 85 - Number(age)]
        },
        LIMITED: {
          range: [6, _isAbove40(85 - Number(age)) ? 40 : 85 - Number(age)]
        },
        REGULAR: {
          range: [5, _isAbove40(85 - Number(age)) ? 40 : 85 - Number(age)]
        }
      };
    },
    ppt: (term) => {
      return {
        SINGLE: { fixed: [1] },
        LIMITED: { range: [5, Number(term) - 1] },
        REGULAR: { fixed: [Number(term)] }
      };
    },
    description: `The life assured is covered for death benefit during the policy
    term, which can be accelerated in the case of diagnosis of terminal illness. `,
    pasaWindow: [1000000, 10000000],
    ageWindow: [18, 84]
  },
  "Life Plus": {
    term: (age) => {
      return {
        SINGLE: {
          range: [5, _isAbove40(85 - Number(age)) ? 40 : 85 - Number(age)]
        },
        LIMITED: {
          range: [6, _isAbove40(85 - Number(age)) ? 40 : 85 - Number(age)]
        },
        REGULAR: {
          range: [5, _isAbove40(85 - Number(age)) ? 40 : 85 - Number(age)]
        }
      };
    },
    ppt: (term) => {
      return {
        SINGLE: { fixed: [1] },
        LIMITED: { range: [5, Number(term) - 1] },
        REGULAR: { fixed: [Number(term)] }
      };
    },
    description: `The life assured is covered for death benefit, which can be
    accelerated in the case of diagnosis of terminal illness. An additional amount will
    be payable in case of accidental death during policy term.`,
    pasaWindow: [1000000, 10000000],
    ageWindow: [18, 65]
  },
  "Life Goal": {
    term: (age) => {
      return {
        SINGLE: {
          range: [10, _isAbove40(85 - Number(age)) ? 40 : 85 - Number(age)]
        },
        LIMITED: {
          range: [10, _isAbove40(85 - Number(age)) ? 40 : 85 - Number(age)]
        }
      };
    },
    ppt: (term) => {
      return {
        SINGLE: { fixed: [1] },
        LIMITED: { range: [5, Number(term) - 1] }
      };
    },
    description: `The sum assured payable on death would vary with the policy year, in accordance with the ‘Level Cover Period’ and ‘Amortization Rate’ as chosen by the policyholder.`,
    pasaWindow: [1000000, 10000000],
    ageWindow: [18, 65],
    levelCoverPeriod: (ppt, term) => {
      return [...NumberRange(Number(ppt), Number(term - 5))];
    }
  }
};

export const SampoornaJeevanPlans = {
  "Sampoorna Jeevan 75": {
    term: (age) => [75 - age],
    ppt: [6, 8, 10, 12, 15],
    pptTermMap: {
      1: { range: [5, 20] },
      5: { range: [10, 20] },
      6: { range: [12, 20] },
      7: { range: [12, 20] },
      8: { range: [12, 20] },
      9: { range: [12, 20] },
      10: { range: [12, 20] }
    },
    description: ``,
    pasaWindow: [30000, 10000000],
    ageWindow: [30 / 365, 60], //30 days
    defermentPeriod: null
  },
  "Sampoorna Jeevan 100": {
    term: (age) => [100 - age],
    ppt: [6, 8, 10, 12, 15],
    pptTermMap: {
      1: { range: [5, 20] },
      5: { range: [5, 15] },
      6: { range: [6, 15] },
      7: { range: [7, 15] },
      8: { range: [8, 15] },
      9: { range: [9, 15] },
      10: { range: [10, 20] },
      11: { range: [11, 20] },
      12: { range: [12, 20] }
    },
    description: ``,
    pasaWindow: [30000, 10000000],
    ageWindow: [30 / 365, 65],
    incomeTerm: [10, 12],
    defermentPeriod: {
      7: [0, 1, 2, 3, 4, 5],
      8: [0, 1, 2, 3, 4, 5],
      9: [0, 1, 2, 3, 4, 5],
      10: [0, 1, 2, 3, 4, 5],
      11: [0, 1, 2, 3, 4, 5],
      12: [0, 1, 2, 3, 4, 5]
    }
  }
};

export const GuaranteedWealthPlusPlans = {
  "Lump Sum Variant": {
     term: () => [12,15],
     ppt: [6, 8, 10],
     description: `Receive a lumpsum amount as maturity benefit at the end of the policy term chosen to fulfill your needs.`,
     pasaWindow: [30000, 10000000],
     ageWindow: [30 / 365, 60], //30 days
     defermentPeriod: null
   },
   "Income Variant": {
     term: (ppt,payoutPeriod) => [Number(ppt) + Number(payoutPeriod) +1],
     ppt: [5,6,7,8,10,12],
     pptTermMap: {
       1: { range: [5, 20] },
       5: { range: [5, 15] },
       6: { range: [6, 15] },
       7: { range: [7, 15] },
       8: { range: [8, 15] },
       9: { range: [9, 15] },
       10: { range: [10, 20] },
       11: { range: [11, 20] },
       12: { range: [12, 20] }
     },
     description: `Receive Guaranteed Income annually in arrears commencing from end of 8th policy year for a fixed period of 30 years. Also, you will get back premiums paid on maturity.`,
     pasaWindow: [30000, 10000000],
     ageWindow: [30 / 365, 65],
     incomeTerm: [10, 12],
     defermentPeriod: {
       7: [0, 1, 2, 3, 4, 5],
       8: [0, 1, 2, 3, 4, 5],
       9: [0, 1, 2, 3, 4, 5],
       10: [0, 1, 2, 3, 4, 5],
       11: [0, 1, 2, 3, 4, 5],
       12: [0, 1, 2, 3, 4, 5]
     }
   },
} 

export const c2aPlans = {
  "Smart Student": {
    // term: (age) => {
    //   return [...NumberRange(10, 40), 100 - Number(age)];
    // },
    ppt: [5, 6, 7, 8, 9, 10, 12],
    shortDescription:
      "A plan designed to provide flexibility to devise a tailored strategy that guarantees their aspirations materialize into reality",
    description: null,
    pasaWindow: [0, 10000000],
    ageWindow: [0, 99]
  },
  "Dream Achiever": {
    // term: (age) => {
    //   return [...NumberRange(10, 40), 100 - Number(age)];
    // },
    ppt: {
      Moneyback: [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
      Lumpsum: [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35],
      Income: [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35],
      'Early Income': [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35],
    },
    term: {
      Lumpsum: [10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40],
      Moneyback: [10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]
    },
    maxAge: {
      Moneyback: 65,
      Lumpsum: 65,
      Income: 65,
      'Early Income': 65
    },
    shortDescription: `An option that provides versatile and comprehensive savings solution...`,
    description:
      "An option that provides versatile and comprehensive savings solution, meticulously crafted to effortlessly align with your financial aspirations. Discover the perfect avenue to safeguard your future, regardless of your savings objectives",
    pasaWindow: [0, 10000000],
    ageWindow: [18, 99],

    payoutPeriod: {
      'Early Income': new Array(31).fill(null).map((_,i)=>({ label: `${i + 10} Years`, value: i + 10 })),
      Income: {
        No: new Array(31).fill(null).map((_, i) => ({ label: `${i + 5} Years`, value: i + 5 })),
        Yes: [
          { value: 5, label: "5 years" },
          { value: 10, label: "10 years" },
          { value: 15, label: "15 years" },
          { value: 20, label: "20 years" },
          { value: 25, label: "25 years" },
          { value: 30, label: "30 years" },
          { value: 35, label: "35 years" }
        ]
      },
      Lumpsum: [
      ],
      Moneyback: [
        { label: "Last 5y", value: "Last 5y" },
        { label: "Regular", value: "Regular" }
      ]
    }
  }
};

export const sanchayPlusPlans = {
  "Guaranteed Maturity": {
    term: [10, 12, 20],
    ppt: [5, 6, 10],
    pptTermMap: {
      1: { range: [5, 20] },
      5: { range: [10, 20] },
      6: { range: [12, 20] },
      7: { range: [12, 20] },
      8: { range: [12, 20] },
      9: { range: [12, 20] },
      10: { range: [12, 20] }
    },
    description: `A plan option which offers guaranteed** benefit payable as lump sum at maturity.`,
    pasaWindow: [30000, 10000000],
    ageWindow: [30 / 365, 60], //30 days
    defermentPeriod: null
  },
  "Guaranteed Income": {
    term: [11, 13],
    ppt: [10, 12],
    pptTermMap: {
      1: { range: [5, 20] },
      5: { range: [5, 15] },
      6: { range: [6, 15] },
      7: { range: [7, 15] },
      8: { range: [8, 15] },
      9: { range: [9, 15] },
      10: { range: [10, 20] },
      11: { range: [11, 20] },
      12: { range: [12, 20] }
    },
    description: `A plan option which offers maturity benefit in the form of Guaranteed** Regular Income for a fixed term of 10 or 12 years.`,
    pasaWindow: [30000, 10000000],
    ageWindow: [30 / 365, 65],
    incomeTerm: [10, 12],
    defermentPeriod: {
      7: [0, 1, 2, 3, 4, 5],
      8: [0, 1, 2, 3, 4, 5],
      9: [0, 1, 2, 3, 4, 5],
      10: [0, 1, 2, 3, 4, 5],
      11: [0, 1, 2, 3, 4, 5],
      12: [0, 1, 2, 3, 4, 5]
    }
  },
  "Life Long Income": {
    term: [6, 11],
    ppt: [5, 10],
    pptTermMap: {
      1: { range: [5, 10] },
      5: { range: [5, 15] },
      6: { range: [6, 15] },
      7: { range: [7, 15] },
      8: { range: [8, 15] },
      9: { range: [9, 15] },
      10: { range: [10, 20] },
      11: { range: [11, 20] },
      12: { range: [12, 20] }
    },
    description: `A plan option which offers maturity benefit in the form of Guaranteed** Regular Income up to age of 99 years and return of total premiums paid at the end of payout period.`,
    pasaWindow: [30000, 10000000],
    ageWindow: [50, 65],
    defermentPeriod: 1
  },
  "Long Term Income": {
    term: [6, 11],
    ppt: [5, 10],
    pptTermMap: {
      1: { range: [5, 15] },
      5: { range: [5, 15] },
      6: { range: [6, 15] },
      7: { range: [7, 15] },
      8: { range: [8, 15] },
      9: { range: [9, 15] },
      10: { range: [10, 15] },
      11: { range: [11, 15] },
      12: { range: [12, 15] }
    },
    description: `A plan option which offers maturity benefit in the form of Guaranteed** Income for a fixed term of 25 or 30 years and return of total premiums paid at the end of payout period.`,
    pasaWindow: [30000, 10000000],
    ageWindow: [30 / 365, 60], //30 days
    incomeTerm: [25, 30],
    defermentPeriod: {
      5: [0, 1, 2, 3, 4, 5],
      6: [0, 1, 2, 3, 4, 5],
      7: [0, 1, 2, 3],
      8: [0, 1, 2, 3],
      9: [0, 1, 2],
      10: [0, 1, 2],
      11: [0, 1],
      12: [0, 1]
    }
  }
};

export const sanchayParPlans = {
  "Immediate Income": {
    term: (age) => {
      return [...NumberRange(20, 40), 100 - Number(age)];
    },
    ppt: [5, 6, 7, 8, 9, 10, 12],
    shortDescription:
      "An option that provides regular income by way of cash bonuses (if declared), from 1st policy year and provides lump sum at maturity thereby creating a legacy for your loved one.",
    description: null,
    pasaWindow: [0, 10000000],
    ageWindow: [18, 99]
  },
  "Deferred Income": {
    term: (age) => {
      return [...NumberRange(20, 40), 100 - Number(age)];
    },
    ppt: [7, 8, 9, 10, 12],
    shortDescription: `An option that provides Guaranteed Income for a guarantee period<sup>@</sup>...`,
    description: `An option that provides Guaranteed Income for a guarantee period<sup>@</sup>, and also provides regular income by way of cash bonuses (if declared) throughout the policy term. It helps create a legacy for your loved ones by providing a lump sum at maturity.
    <br/><br/><span class='small-text'><sup>@</sup> Guaranteed Income starts after Premium Payment Term + 1 year, provided all due premiums have been paid and the policy is in force. Guaranteed Income and Cash Bonus payouts, if declared, start one year after the end of Premium Payment Term. Guarantee Period is a period which is lower of 25 years, or Policy Term minus (Premium Payment Term + 1) years.</span>`,
    pasaWindow: [0, 10000000],
    ageWindow: [18, 99]
  }
};

// export const brochureLinks = {
//   C2PL: "https://pasa-docs.s3.ap-south-1.amazonaws.com/c2pl-brochure.pdf",
//   SANCHAY: "https://pasa-docs.s3.ap-south-1.amazonaws.com/sanchay+brochure.pdf",
//   SANCHAYPAR:
//     "https://pasa-docs.s3.ap-south-1.amazonaws.com/sanchay-par-advantage-brochure.pdf",
//   SANCHAYPLUS:
//     "https://pasa-docs.s3.ap-south-1.amazonaws.com/Sanchay-Plus_v09-Brochure.pdf",
//   SJB: "https://pasa-docs.s3.ap-south-1.amazonaws.com/sjb-brochure.pdf"
// };

export const brochureLinks = {
  C2PL: "https://instainsure-static-docs.apps-hdfclife.com/c2pl-brochure.pdf",
  SANCHAY:
    "https://instainsure-static-docs.apps-hdfclife.com/sanchay+brochure.pdf",
  SANCHAYPAR:
    "https://instainsure-static-docs.apps-hdfclife.com/sanchay-par-advantage-brochure.pdf",
  SANCHAYPLUS:
    "https://instainsure-static-docs.apps-hdfclife.com/Sanchay-Plus_v09-Brochure.pdf",
  SJB: "https://instainsure-static-docs.apps-hdfclife.com/sjb-brochure.pdf",
  C2PS: "https://instainsure-static-docs.apps-hdfclife.com/HDFC_Life_Click_2_Protect_Super_Retail_Brochure.pdf",
  C2A: "https://www.hdfclife.com/content/dam/hdfclifeinsurancecompany/products-page/brochure-pdf/click-2-achieve-brochure.pdf",
  GWP : "https://www.hdfclife.com/content/dam/hdfclifeinsurancecompany/products-page/brochure-pdf/new-brochure/HDFC-Life-GUARANTEED-WEALTH-PLUS-BROCHURE.pdf",
  ESJ: "https://www.hdfclife.com/content/dam/hdfclifeinsurancecompany/products-page/brochure-pdf/new-brochure/HDFC-SAMPOORNA-JEEVAN-BROCHURE.pdf",
};

export const SJB_EDUCATION = [
  { title: "Post Graduate", value: "POSTGRADUATE" },
  { title: "Graduate", value: "GRADUATE" },
  { title: "12th", value: "HSC" },
  { title: "10th", value: "SSC" },
  { title: "Under Matric (Class 1 to 9)", value: "UNDERMATRIC" },
  { title: "Others", value: "OTHERS" }
];

export const IIFL_AGENT_CODES = [
  { agCode: `00448575`, agName: "Harish Kumar N." },
  { agCode: `00448576`, agName: "DR.B.Praveen Reddy" },
  { agCode: `00453983`, agName: "Bootharaya Aruna" },
  { agCode: `00460448`, agName: "Venkateswaran Morla" },
  { agCode: `00506645`, agName: "Sheetal Patkar" },
  { agCode: `00547775`, agName: "Naveen Sharma" },
  { agCode: `00547889`, agName: "Pooja Rai" },
  { agCode: `00734959`, agName: "Amol Lalit Chaudhari" },
  { agCode: `00767198`, agName: "Gangi Reddy Karri" },
  { agCode: `00781224`, agName: "Srinivas V" },
  { agCode: `00787526`, agName: "Ragina Mary" },
  { agCode: `00804077`, agName: "Meenakshi Aparajith" },
  { agCode: `00804078`, agName: "Pankaj Raj" },
  { agCode: `00817911`, agName: "T.Suresh Babu" },
  { agCode: `00844226`, agName: "Santosh Mehatre" },
  { agCode: `00895424`, agName: "Ayushi Chhawchharia" },
  { agCode: `00906378`, agName: "Ravikiran Angidi" },
  { agCode: `00916308`, agName: "Aditya Jain" },
  { agCode: `00958074`, agName: "Mukesh Kumar Kumawat" },
  { agCode: `00958075`, agName: "Amit Kumar" },
  { agCode: `01004625`, agName: "Srinivasulu Boddu" },
  { agCode: `01053271`, agName: "Pawar Shweta" },
  { agCode: `01055192`, agName: "Polkam Laxmi" },
  { agCode: `01075565`, agName: "Tripathi Brijesh Pati" },
  { agCode: `01123808`, agName: "AMS-IIFL Insurance Brokers Lim" },
  { agCode: `01126119`, agName: "Sneha Dayala" },
  { agCode: `01182562`, agName: "Sowmya B S" },
  { agCode: `01194715`, agName: "Assisted-IIFL Insurance Broker" },
  { agCode: `00000000`, agName: "Others" }
];

export const productMap = {
  SANCHAYPLUS: 'SanchayPlus',
  SANCHAYPAR: 'SanchayPar',
  SJB: 'SJB',
  C2PS: 'C2PS',
  C2A: 'C2A',
  GWP: 'GWP',
  ESJ: "ESJ",

}
export const productDescription = {
  ESJ: {
    product: "ESJ",
    title: "Sampoorna Jeevan",
    description: `is a unique Life Insurance cum savings plan which provides financial protection to your
    family in your absence. It also gives you survival and/or maturity benefit to fulfill your family's growing needs and
    ensures that they enjoy a happy life at all times.`
  },
  GWP: {
    product: "GWP",
    title: "Guaranteed Wealth Plus",
    description: ` a non-linked, non-participating individual life insurance plan that provides
    Guaranteed Death Benefit during the term of the policy. It offers two variants - Lump sum variant and Income variant -
    to choose from at the inception of the policy and depending on the variant chosen`
  },
  C2A: {
    product: 'C2A',
    title: 'Click 2 Achieve',
    description: `A Non-Linked, Non-Participating, Individual
  Savings Life Insurance Plan that offers
  flexibility to customize the benefits. HDFC Life
  Click 2 Achieve is meticulously designed to offer
  versatility, presenting you with a spectrum of
  options to safeguard your loved ones' futures and
  attain your financial objectives`

  },
  C2PL: {
    product: 'C2PL',
    title: 'Click 2 Protect Life',
    description: `An intelligent term plan that provides benefits as
  per your altering lifestyle and life stage needs.
  It helps you stay protected through the different
  phases of your life and provides safety and
  security to your dearest ones.`
  },
  C2PS: {
    product: 'C2PS',
    title: "Click 2 Protect Super",
    description: `A Non-Linked, Non Participating, Individual, Pure
    Risk Premium / Savings LIfe Insurance Plan. A plan
    that provides you varying cover along with
    comprehensive protection as per your evolving
    needs.`
  },
  SANCHAYPLUS: {
    product: 'SANCHAYPLUS',
    title: "Sanchay Plus",
    description: `A non-linked, savings insurance plan that offers
    returns whilst safeguarding your family's future
    against unforeseen events. It enables systematic
    savings and provides regular income so that you
    can fulfill your family’s dreams and commitments
    with ease.`
  },

  SANCHAYPAR: {
    product: 'SANCHAYPAR',
    title: "Sanchay Par Advantage",
    description: `A suitable plan that provides regular income and
    an option to avail cover for whole of life (till
    the age 100 years). HDFC Life Sanchay Par
    Advantage gives you a secure source of income that
    takes care of rising expenses, more so after your
    retirement or in case of unfortunate demise.`
  },
  SJB: {
    product: 'SJB',
    title: "Saral Jeevan Bima",
    description: `The Saral Jeevan Bima Yojana is a non-linked
    non-participating individual pure risk term life
    insurance plan, which pays out to the policy
    holder's dependents the entire sum assured, in
    case of the demise of the insured person within
    the policy term.`
  }
}

export const parentSecureNomineeRel = [
  { name: "Father", value: "father", gender: "M", category: "P" },
  { name: "Mother", value: "mother", gender: "F", category: "P" },
];
